import { useState, useEffect } from 'react'

import EVENTS_NAMES from '../../../../../analytics/eventsNames.json'
import { User, Session } from '../../../../../business'
import { browser } from '../../../../utils'
import { View, WIZARD_VARIANTS } from './View'

const getUserHasNavigatedInMedia = async () => {
  const readArticles = await User.getArticlesViewedByUser({
    page: 1,
    limit: 1,
  })
  return Boolean(readArticles?.data?.length)
}

const setStorageHideWizardFlag = () => Session.setHideWizardFlag('1')

const setShowStorageWizardFlag = () => Session.setHideWizardFlag('0')

const doExistsStorageHideWizardFlag = () => Boolean(Session.getHideWizardFlag())

const isStorageHideWizardFlagSet = () => Session.getHideWizardFlag() !== '0'

const AddToHomeScreenWizard = () => {
  const [isFetchingReadArticles, setIsFetchingReadArticles] = useState(false)
  const [isHideWizardClicked, setIsHideWizardClicked] = useState(false)

  const handleClickClose = () => {
    setIsHideWizardClicked(true)
    setStorageHideWizardFlag()

    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_WIZARD_HOME_CLOSE,
        },
      }),
    )
  }

  const handleSlideChange = ({ snapIndex }) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_WIZARD_HOME_SHOW,
          step: snapIndex,
        },
      }),
    )
  }

  useEffect(() => {
    if (doExistsStorageHideWizardFlag()) {
      return
    }
    ;(async () => {
      setIsFetchingReadArticles(true)
      if (await getUserHasNavigatedInMedia()) {
        setShowStorageWizardFlag()
      }

      setIsFetchingReadArticles(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    isFetchingReadArticles ||
    browser.isDesktop() ||
    isStorageHideWizardFlagSet() ||
    isHideWizardClicked
  ) {
    return null
  }

  document.dispatchEvent(
    new CustomEvent('payperAnalyticsPrivateEvent', {
      detail: {
        name: EVENTS_NAMES.PD_WIZARD_HOME_SHOW,
        step: 0,
      },
    }),
  )

  return (
    <View
      onClickClose={handleClickClose}
      variant={
        browser.isAndroid()
          ? WIZARD_VARIANTS.ANDROID
          : browser.isChrome()
          ? WIZARD_VARIANTS.IOS_CHROME
          : WIZARD_VARIANTS.IOS_SAFARI
      }
      onSlideChange={handleSlideChange}
    />
  )
}

export default AddToHomeScreenWizard
