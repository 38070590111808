import { useNavigate } from 'react-router-dom'

import { User } from '../../../../business'
import View from './View'

const AccountPage = () => {
  const navigate = useNavigate()

  const { state: bankCard } = User.useBankCard()
  const { state: balance } = User.useBalance()
  const {
    state: { data: userData },
  } = User.usePersonalData()
  const bonus = User.useActiveBonus()

  const onClickTopUp = () => {
    navigate('top-up-balance')
  }
  const onClickEditBankCard = () => {
    navigate('bank-card-edition')
  }
  const onClickAddBankCard = () => {
    navigate('bank-card-introduction')
  }
  const onClickUpdatePersonalData = () => {
    navigate('update-personal-data')
  }
  const onClickUpdatePassword = () => {
    navigate('update-password')
  }

  return (
    <View
      bankCard={bankCard}
      balance={balance}
      userData={userData}
      bonus={bonus}
      onClickTopUp={onClickTopUp}
      onClickEditBankCard={onClickEditBankCard}
      onClickAddBankCard={onClickAddBankCard}
      onClickUpdatePersonalData={onClickUpdatePersonalData}
      onClickUpdatePassword={onClickUpdatePassword}
    />
  )
}

export default AccountPage
