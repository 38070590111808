import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Session } from '../../../../business'
import View from './View'
import SuccessView from './SuccessView'
import useUpdatePasswordForm from './useUpdatePasswordForm'

const FORM_ID = 'update-password-form'

const UpdatePassword = () => {
  const navigate = useNavigate()
  const { status, isSubmitSuccess, submitForm } = useUpdatePasswordForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  useEffect(() => {
    if (Session.getIsIdpAccount()) {
      navigate('..', { relative: 'path' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isSubmitSuccess() ? (
        <SuccessView />
      ) : (
        <View formId={FORM_ID} onSubmit={handleSubmit} {...status} />
      )}
    </>
  )
}

export default UpdatePassword
