// import { CookiesLayer } from '../../components'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
import Header from './Header'
import Footer from './Footer'

const PublicLayout = ({ children, withoutHeader }) => {
  return (
    <ScrollRevisionOnPageChange>
      {!withoutHeader && <Header />}
      {children}
      <Footer />
      {/* <CookiesLayer /> */}
    </ScrollRevisionOnPageChange>
  )
}

export default PublicLayout
