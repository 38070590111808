const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    // rowGap: 6.375,
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    height: (theme) => theme.spacing(12.5),
    p: 1.5,
    boxShadow: 1,
    borderRadius: (theme) => theme.spacing(1.25),
    backgroundColor: 'neutral.white',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(2),
    color: 'neutral.main',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardData: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    fontWeight: 500,
    color: 'neutral.dark',
  },
}

export default styles
