import { useNavigate, useLocation } from 'react-router-dom'
import { Box, useTheme, useMediaQuery } from '@mui/material'

import { nativeApps } from '../../thirdPartyIntegration'
import { User } from '../../business'
import { useTexts } from '../../texts'
import { Icons } from '../../assets'
import { NAVIGATION_ITEMS, AUTHENTICATED_DEFAULT_ROUTE } from '../constants'
import { Avatar, MobileBottomNavigation, AppBarNavigation } from '../components'
import Footer from './Private/Footer'
import ScrollRevisionOnPageChange from '../ScrollRevisionOnPageChange'

const ROOT_ID = 'root-node'

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    height: '100%',
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: {
      xs: 2.5,
      md: 3.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(59.5)})`,
    mx: 'auto',
    my: {
      xs: 2,
      md: 3,
    },

    '& h2': {
      textAlign: {
        md: 'center',
      },
    },
  },
}

export const MainPageContentLayout = ({ children, sx = {} }) => (
  <Box component='main' sx={{ ...styles.main, ...sx }}>
    {children}
  </Box>
)

const MainPageLayoutView = ({ children, withoutFooter }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const texts = useTexts()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const getUserName = (name) =>
    name !== undefined && name !== '.' ? name : texts.myAccount()

  const {
    state: { data: user },
  } = User.usePersonalData()

  const actions = [
    {
      name: NAVIGATION_ITEMS.DISCOVERY,
      icon: <Icons.Search height='24px' width='24px' />,
      label: texts.discovery(),
      path: '/discovery',
    },
    {
      name: NAVIGATION_ITEMS.LIBRARY,
      icon: <Icons.Library height='24px' width='24px' />,
      label: texts.library(),
      path: '/library',
    },
    {
      name: NAVIGATION_ITEMS.ACCOUNT,
      icon: <Avatar user={user} size={24} />,
      label: getUserName(user?.name),
      path: '/account',
    },
  ]

  const handleNavigation = (menuItem) => {
    navigate(menuItem.path)
  }
  const handleGoHome = () => {
    navigate(AUTHENTICATED_DEFAULT_ROUTE)
  }

  const getActiveAction = () => {
    const active = actions.find((action) =>
      location.pathname.includes(action.path),
    )
    return !!active ? active.name : null
  }

  return (
    <ScrollRevisionOnPageChange>
      {!nativeApps.isEmbeddedInNativeApp() && widerThanTablet && (
        <AppBarNavigation
          actions={actions}
          onClickMenuItem={handleNavigation}
          onClickHome={handleGoHome}
          activeAction={getActiveAction()}
        />
      )}
      <Box sx={styles.root} id={ROOT_ID}>
        {children}

        {!withoutFooter && <Footer />}
      </Box>
      {!nativeApps.isEmbeddedInNativeApp() && !widerThanTablet && (
        <MobileBottomNavigation actions={actions} />
      )}
    </ScrollRevisionOnPageChange>
  )
}

const MainPageLayout = ({ children, withoutFooter }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>
          <MainPageLayoutView withoutFooter={withoutFooter}>
            {children}
          </MainPageLayoutView>
        </User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export default MainPageLayout
