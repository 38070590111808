import baseTheme from '../base'

const MuiTableCell = {
  styleOverrides: {
    root: {
      borderBottom: 'none',
      color: baseTheme.palette.neutral.dark,

      '&.MuiTableCell-head': {
        padding: baseTheme.spacing(0.5, 1),
        borderBottom: `${baseTheme.spacing(0.25)} solid transparent`,
        fontSize: baseTheme.spacing(0.75),
        fontWeight: 400,
        boxShadow: `0 -1px 0 0 rgb(156 156 156 / 20%) inset`,

        [baseTheme.breakpoints.up('md')]: {
          fontSize: baseTheme.spacing(0.875),
        },
        [baseTheme.breakpoints.up('lg')]: {
          paddingLeft: baseTheme.spacing(1.5),
        },

        '&:nth-of-type(1)': {
          width: '11%',
        },
        '&:nth-of-type(2)': {
          width: '65%',
          minWidth: baseTheme.spacing(12.5),
        },
        '&:nth-of-type(3)': {
          width: '12%',
        },
        '&:nth-of-type(4)': {
          width: '12%',
        },
      },
      '&.MuiTableCell-body': {
        padding: baseTheme.spacing(0.75, 1),
        fontSize: baseTheme.spacing(0.75),

        '&:nth-of-type(2), &:nth-of-type(4)': {
          fontWeight: 500,
          color: baseTheme.palette.neutral.black,
        },

        [baseTheme.breakpoints.up('md')]: {
          fontSize: baseTheme.spacing(0.875),
        },
        [baseTheme.breakpoints.up('lg')]: {
          padding: baseTheme.spacing(1.125, 1.5),
        },
      },
    },
  },
}

export default MuiTableCell
