import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'

import { useTexts } from '../../../texts/index.js'
import { Icons } from '../../../assets/index.js'
import { Chip } from '../index.js'
import styles from './bankCardWithEditionButton.styles.js'

const cardBankPropTypes = {
  bankCardData: PropTypes.shape({
    cardNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    isExpired: PropTypes.bool,
  }),
  onClickEditBankCard: PropTypes.func.isRequired,
  onClickAddBankCard: PropTypes.func.isRequired,
}

const BANK_CARD_TYPES = {
  EMPTY: 'empty',
  VALID: 'valid',
  INVALID: 'invalid',
}

const BankCardWithEditionButton = ({
  bankCardData,
  onClickEditBankCard,
  onClickAddBankCard,
}) => {
  const texts = useTexts()

  const type = !Boolean(bankCardData)
    ? BANK_CARD_TYPES.EMPTY
    : bankCardData.isExpired
    ? BANK_CARD_TYPES.INVALID
    : BANK_CARD_TYPES.VALID

  const CHIP_BY_TYPE = {
    [BANK_CARD_TYPES.EMPTY]: {
      text: texts.getBankCardNotRegisteredLabel(),
      variant: 'error',
    },
    [BANK_CARD_TYPES.INVALID]: {
      text: texts.getBankCardOutdatedLabel(),
      variant: 'error',
    },
    [BANK_CARD_TYPES.VALID]: {
      text: texts.getBankCardUpdatedLabel(),
      variant: 'success',
    },
  }

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardContent}>
        <Box sx={styles.top}>
          <Box sx={styles.left}>
            <Chip {...CHIP_BY_TYPE[type]} />
            <Box sx={styles.icon}>
              <Icons.Contactless />
            </Box>
          </Box>
        </Box>

        {type !== BANK_CARD_TYPES.EMPTY && (
          <Box sx={styles.bottom}>
            <Typography component='span' sx={styles.cardData}>
              {`•••• •••• •••• ${bankCardData.cardNumber?.slice(-4)}`}
            </Typography>
            <Typography component='span' sx={styles.cardData}>
              {bankCardData.expiryDate}
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        id='dashboard-top-up-btn'
        fullWidth
        color='secondary'
        sx={styles.cta}
        onClick={
          type === BANK_CARD_TYPES.EMPTY
            ? onClickAddBankCard
            : onClickEditBankCard
        }
      >
        {type === BANK_CARD_TYPES.EMPTY
          ? texts.getBankCardIntroAction()
          : texts.getBankCardEditAction()}
      </Button>
    </Box>
  )
}

BankCardWithEditionButton.propTypes = cardBankPropTypes

export default BankCardWithEditionButton
