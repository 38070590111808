import PropTypes from 'prop-types'

import { CircularProgress } from '../../../components'
import * as PT from '../../../propTypes'
import useAccess from './useAccess'
import Modal from './Modal'
import Steps from './Steps'

export const variantPropType = PropTypes.oneOf([
  { name: 'STANDARD' },
  { name: 'PROMOTION', partner: PT.Partner },
  { name: 'PARTNER_MANDATORY_WARNING' },
  { name: 'PARTNER_REGISTRATION_ERROR' },
])

const Access = () => {
  const {
    unauthenticationIsConfirmedInLoad,
    handleCloseButton,
    ...stepsProps
  } = useAccess()

  if (!Boolean(unauthenticationIsConfirmedInLoad))
    return <CircularProgress height='80vh' />

  return (
    <Modal onCancel={handleCloseButton} partner={stepsProps.variant?.partner}>
      <Steps {...stepsProps} />
    </Modal>
  )
}

export default Access
