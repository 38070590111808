import theme from '../../../theme'

const styles = {
  dark: {
    '--background': (theme) => theme.palette.neutral.black,
    '--heading': (theme) => theme.palette.neutral.white,
    '--text': (theme) => theme.palette.neutral.white,
  },
  solid: {
    '--background': (theme) => theme.palette.neutral.white,
    '--heading': (theme) => theme.palette.neutral.black,
    '--text': (theme) => theme.palette.neutral.main,
  },
  gradient: {
    '--background': (theme) =>
      `linear-gradient(180deg, ${theme.palette.neutral.white} 48%, ${theme.palette.neutral.lightest} 48%)`,
    '--heading': (theme) => theme.palette.neutral.black,
    '--text': (theme) => theme.palette.neutral.main,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.25,
    minHeight: '100vh',
    pt: 1.25,
    px: 1.25,
    pb: 2,
    background: 'var(--background)',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2.5,
    width: (theme) => `min(100%, ${theme.spacing(23.5)})`,
    mt: {
      md: 3.75,
    },
    ml: 'auto',
    mr: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 1.25,
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
  mediaImage: {
    display: 'block',
    width: 'auto',
    maxWidth: '100%',
    height: (theme) => ({
      xs: theme.spacing(1.75),
      lg: theme.spacing(2),
    }),
    mb: {
      xs: 0.25,
      lg: 2,
    },
    alignSelf: 'flex-start',
    objectFit: 'contain',
  },
  text: {
    ...theme.typography.body2,
    color: 'var(--text)',
  },
  heading: {
    ...theme.typography.h4,
    color: 'var(--heading)',
  },
  description: {
    ...theme.typography.body2,
    color: 'var(--text)',
  },
  closeButton: {
    position: {
      xs: 'relative',
      md: 'absolute',
    },
    top: (theme) => ({
      xs: theme.spacing(-0.5),
      md: theme.spacing(1.25),
    }),
    right: (theme) => ({
      md: theme.spacing(2),
    }),

    '& .MuiIconButton-root': {
      p: 0.5,
      fontSize: (theme) => theme.spacing(1.5),
      color: 'var(--heading)',
    },
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footer: {
    isolation: 'isolate',
  },
}

export default styles
