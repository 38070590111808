import { useMediaQuery, useTheme } from '@mui/material'

import { User, useFetch } from '../../../../business'
import { HeroPartner as View } from '../../../components'

const styles = {
  mt: { xs: -2, md: 0 },
}

const useActivePartner = () => useFetch(User.fetchActivePartner)

const HeroPartner = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const partnerData = useActivePartner()

  if (
    partnerData.isLoading ||
    partnerData.data === null ||
    !Boolean(partnerData?.data?.imageUrl)
  ) {
    return null
  }

  return (
    <View
      {...partnerData.data}
      isHeader={!isDesktop}
      className='mobile-full-bleed'
      sx={styles}
    />
  )
}

export default HeroPartner
