const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    height: (theme) => theme.spacing(12.5),
    boxShadow: 1,
    borderRadius: (theme) => theme.spacing(1.25),
    backgroundColor: 'neutral.white',
  },
  cardContent: {
    p: 1.5,
    pb: 1,
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  top: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.75,
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1.75),
    color: 'neutral.main',
  },
  bottom: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  cardData: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    fontWeight: 500,
    color: 'neutral.dark',
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
    borderRadius: '50%',
    fontSize: (theme) => theme.spacing(1.5),
    color: 'neutral.black',
    backgroundColor: 'primary.main',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: 'neutral.lightest',
    },
  },
  cta: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 0.5,
    borderRadius: (theme) => theme.spacing(0, 0, 1.25, 1.25),

    '& > .MuiButton-startIcon': {
      mr: 0,
    },
  },
}

export default styles
