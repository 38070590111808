const overrideMuiButtonStyles = {
  display: 'flex',
  minWidth: (theme) => theme.spacing(1),
  py: 0.25,
  px: 0,
  fontSize: (theme) => ({
    xs: theme.spacing(0.75),
    md: theme.spacing(0.75),
  }),
  borderRadius: 0,

  '&:hover': {
    backgroundColor: 'neutral.light',
  },
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: (theme) => theme.spacing(3.5),
    backgroundColor: 'neutral.light',
  },
  content: {
    width: (theme) => `min(100%, ${theme.spacing(90)})`,
    px: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.5,
    m: 0,
    pl: 0,
    listStyleType: 'none',
  },
  hasArrowRight: {
    position: 'relative',
  },
  item: {
    py: 0.4375,
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 400,
    color: 'neutral.dark',

    '& > .MuiLink-root': {
      textDecoration: 'none',
    },
  },
  active: {
    fontWeight: 500,
    textDecoration: 'underline',
    color: 'neutral.black',
  },
  link: {
    '&.MuiButton-root': {
      ...overrideMuiButtonStyles,
    },
  },
  decorator: {
    display: 'flex',
    color: 'neutral.black',
  },
  icon: {
    color: 'neutral.black',
  },
  iconLink: {
    '&.MuiButton-root': {
      ...overrideMuiButtonStyles,
    },
  },
}

export default styles
