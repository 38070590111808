import {
  Box,
  Typography,
  Button,
  // useTheme
} from '@mui/material'

import { useTexts } from '../../../texts'
// import { Icons } from '../../../assets'

import { AssociatedMedia, HeroPartner } from '../../components'
import { ASSOCIATED_MEDIAS_DATA } from '../../constants'
import { PageContent } from '../../Layout'
import styles from './landingPartner.styles'

const LandingPartnerView = ({ partner, openMediaPage, onClickContinue }) => {
  const texts = useTexts()
  // const theme = useTheme()

  // const BENEFITS_TEXTS = [
  //   {
  //     title: texts.getSummaryTitle(),
  //     text: texts.getSummaryClaim(),
  //     Icon: Icons.Sparkles,
  //   },
  //   {
  //     title: texts.getCoverageTitle(),
  //     text: texts.getCoverageClaim(),
  //     Icon: Icons.Coverage,
  //   },
  //   {
  //     title: texts.getContextTitle(),
  //     text: texts.getContextClaim(),
  //     Icon: Icons.Context,
  //   },
  // ]

  return (
    <PageContent sx={styles.root}>
      <HeroPartner {...partner} className='full-bleed' isHeader />
      <Box sx={styles.section}>
        <Typography
          dangerouslySetInnerHTML={{
            __html:
              partner?.configuration?.claim ??
              texts.getPartnerBenefitsClaim(partner.name),
          }}
          variant='body2'
        />
        <AssociatedMedia
          medias={ASSOCIATED_MEDIAS_DATA}
          onClickMedia={openMediaPage}
        />
        <Box sx={styles.cta}>
          <Button fullWidth color='primary' onClick={onClickContinue}>
            {texts.getRegisterContinueAction()}
          </Button>
        </Box>
      </Box>

      {/* <Box sx={styles.section}>
        <Typography variant='body2'>{texts.getBenefitsClaim()}</Typography>
        <Box sx={styles.benefitsList}>
          {BENEFITS_TEXTS.map(({ title, text, Icon }) => (
            <Box sx={styles.benefitsItem} key={title}>
              <Box className='icon'>
                <Icon color={theme.palette.neutral.dark} />
              </Box>
              <Box className='text'>
                <Typography variant='h5'>{title}</Typography>
                <Typography variant='body2'>{text}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Typography variant='body2'>
          {texts.getContinueToRegistrationClaim()}
        </Typography>
        <Box sx={styles.cta}>
          <Button fullWidth color='primary' onClick={onClickContinue}>
            {texts.getRegisterContinueAction()}
          </Button>
        </Box>
      </Box> */}
    </PageContent>
  )
}

export default LandingPartnerView
