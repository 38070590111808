import baseTheme from '../base'

const MuiInputLabel = {
  defaultProps: {
    shrink: true,
    required: false,
  },
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: baseTheme.spacing(0.25),
      top: baseTheme.spacing(0.25),
      left: baseTheme.spacing(1.6875),
      fontWeight: 500,
      transition: 'all 300ms',

      '&.Mui-error': {
        backgroundColor: baseTheme.palette.error.dark,
      },
    },
  },
}

export default MuiInputLabel
