const styles = {
  description: {
    mt: 1,
  },
  checkList: {
    display: 'flex',
    flexDirection: 'column',
    pl: 0,
    my: 0,

    '& li': {
      display: 'flex',
      alignItems: 'flex-start',
      columnGap: 0.25,
      mt: (theme) => theme.spacing(1.625),

      '& p': {
        mt: 0,
      },
    },
  },
  bulletIcon: {
    p: 0.3,
    pt: 0.1,
  },

  separator: {
    backgroundColor: 'neutral.light',
    mt: (theme) => theme.spacing(2.5),
    height: 2,
  },

  comments: {
    mt: (theme) => theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: (theme) => theme.spacing(13.25),
    },
  },
  comments_itemText: {
    color: 'neutral.main',
  },
  comments_itemAuthor: {
    mt: 0.5,
    fontWeight: 600,
  },

  formContainer: {
    mx: 'auto',
    width: (theme) => theme.spacing(27.5),
    minHeight: 565,
    backgroundColor: 'neutral.white',
    p: 2.5,

    display: 'flex',
    flexDirection: 'column',
    rowGap: 2.5,
    textAlign: 'center',
  },
}

export default styles
