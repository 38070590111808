import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Tab,
  Tabs as MuiTabs,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const styles = {
  tabs: {
    py: (theme) => theme.spacing(1),
  },
  panel: {},
}

const applyFixedTabsStyles = (styles) => {
  styles.tabs = {
    ...styles.tabs,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'neutral.white',
  }
  styles.panel = {
    ...styles.panel,
    flexGrow: 1,
    overflowY: 'auto',
  }
}

const TabPanelPropTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

TabPanel.propTypes = TabPanelPropTypes

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

const TabsPropTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      content: PropTypes.node.isRequired,
    }),
  ),
  activeTab: PropTypes.number,
  ariaLabel: PropTypes.string,
  onClickTab: PropTypes.func,
}

const Tabs = ({ tabs, activeTab = 0, ariaLabel, onClickTab = () => {} }) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  if (!widerThanTablet) {
    applyFixedTabsStyles(styles)
  }

  const [value, setValue] = useState(activeTab)

  const handleChange = (_, newValue) => {
    setValue(newValue)
    onClickTab(tabs[newValue]?.name ?? tabs[newValue]?.label)
  }

  return (
    <>
      <MuiTabs
        aria-label={ariaLabel}
        sx={styles.tabs}
        value={value}
        onChange={handleChange}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            icon={tab?.icon}
            iconPosition='start'
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.label} value={value} index={index} sx={styles.panel}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  )
}

Tabs.propTypes = TabsPropTypes

export default Tabs
