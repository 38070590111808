const styles = {
  info: {
    '--root-bgColor': (theme) => theme.palette.info.lightest,
    '--icon-bgColor': (theme) => theme.palette.info.light,
    '--icon-color': (theme) => theme.palette.info.main,
    '--title-color': (theme) => theme.palette.info.dark,
  },
  error: {
    '--root-bgColor': (theme) => theme.palette.error.lightest,
    '--icon-bgColor': (theme) => theme.palette.error.light,
    '--icon-color': (theme) => theme.palette.error.main,
    '--title-color': (theme) => theme.palette.error.dark,
  },
  success: {
    '--root-bgColor': (theme) => theme.palette.success.lightest,
    '--icon-bgColor': (theme) => theme.palette.success.light,
    '--icon-color': (theme) => theme.palette.success.main,
    '--title-color': (theme) => theme.palette.success.dark,
  },
  warning: {
    '--root-bgColor': (theme) => theme.palette.warning.lightest,
    '--icon-bgColor': (theme) => theme.palette.warning.light,
    '--icon-color': (theme) => theme.palette.warning.main,
    '--title-color': (theme) => theme.palette.warning.dark,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.75,
    p: 0.5,
    borderRadius: (theme) => theme.spacing(0.25),
    boxShadow: 3,
    backgroundColor: 'var(--root-bgColor)',
  },
  icon: {
    display: 'flex',
    p: 0.25,
    borderRadius: (theme) => theme.spacing(10),
    fontSize: (theme) => theme.spacing(1.25),
    color: 'var(--icon-color)',
    backgroundColor: 'var(--icon-bgColor)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.25,
    py: 0.375,
  },
  title: {
    fontSize: (theme) => theme.spacing(1),
    fontWeight: 500,
    color: 'var(--title-color)',
  },
  text: {
    fontSize: (theme) => ({ xs: theme.spacing(0.75), md: theme.spacing(0.75) }),
    fontWeight: 500,
    lineHeight: 1.4,
    color: 'neutral.main',
  },
  link: {
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 600,
    lineHeight: 1.4,
    color: 'neutral.main',
  },
}

export default styles
