import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { AppBar, Box, Button } from '@mui/material'

import { useTexts } from '../../../../texts'
import { Icons } from '../../../../assets'
import styles from './header.styles'

const Appbar = ({ onGoToAccess, scrollPosition }) => {
  const texts = useTexts()

  return (
    <AppBar sx={styles.root} id='public-header'>
      <Box sx={styles.toolbar}>
        <Box sx={styles.logo} component={Link} to='/'>
          <Icons.PayperLogo aria-label='Payper logo' color='#121212' />
        </Box>
        <Box sx={styles.actions}>
          <Button
            id='header-access-btn'
            color='secondary'
            onClick={onGoToAccess}
            sx={styles.button}
          >
            {texts.getLoginCtaAction()}
          </Button>
        </Box>
      </Box>
      <Box sx={styles.track} style={{ '--scrollPosition': scrollPosition }} />
    </AppBar>
  )
}

Appbar.propTypes = {
  onGoToRegister: PropTypes.func,
  onGoToLogin: PropTypes.func,
}

export default Appbar
