const separator = {
  position: 'relative',

  '&::after': {
    content: '""',
    display: {
      xs: 'block',
      lg: 'none',
    },
    height: '100%',
    width: '1px',
    position: 'absolute',
    right: '-7px',
    top: 0,
    backgroundColor: 'neutral.light',
  },
}

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
    width: (theme) => `min(100%, ${theme.spacing(79)})`,
    mx: 'auto',
  },
  tableHeaderRow: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'auto 1fr auto auto',
      lg: '80px 1fr min(15%, 100px) min(15%, 60px)',
    },
    gridTemplateRows: '1fr',
    gridTemplateAreas: {
      xs: '"mediaProvider headline date price"',
      lg: '"date headline mediaProvider price"',
    },
    columnGap: {
      xs: 0.75,
      lg: 3,
    },
    pt: {
      xs: 0.85,
      lg: 0.75,
    },
    px: {
      xs: 1.25,
      lg: 1.5,
    },
    pb: {
      xs: 0.5,
    },
    mb: 0.5,
    borderBottom: '1px solid rgba(156, 156, 156, 0.2)',
  },
  tableHeaderCell: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      lg: theme.spacing(0.875),
    }),
    fontWeight: 400,
    color: 'neutral.darkest',

    '&[data-field="date"]': {
      ...separator,
      gridArea: 'date',
    },
    '&[data-field="headline"]': {
      gridArea: 'headline',
    },
    '&[data-field="mediaProvider"]': {
      ...separator,
      gridArea: 'mediaProvider',
    },
    '&[data-field="price"]': {
      gridArea: 'price',
    },
  },
  tableBodyRow: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr 80px',
      lg: '80px 1fr min(15%, 100px) min(15%, 60px)',
    },
    gridTemplateRows: { xs: 'auto 1fr', lg: '1fr' },
    gridTemplateAreas: {
      xs: '"mediaProvider date" "headline price"',
      lg: '"date headline mediaProvider price"',
    },
    py: {
      xs: 0.5,
      lg: 1,
    },
    px: {
      xs: 1.25,
      lg: 1.5,
    },
    rowGap: {
      xs: 0.125,
      lg: 0,
    },
    columnGap: {
      xs: 1,
      lg: 3,
    },
  },
  tableBodyCell: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      lg: theme.spacing(0.875),
    }),
    fontWeight: 400,
    color: 'neutral.dark',

    '&[data-field="date"]': {
      gridArea: 'date',
      textAlign: {
        xs: 'right',
        lg: 'left',
      },
    },
    '&[data-field="headline"]': {
      gridArea: 'headline',
      fontSize: (theme) => theme.spacing(0.875),
      fontWeight: 500,
      color: 'neutral.black',
    },
    '&[data-field="mediaProvider"]': {
      gridArea: 'mediaProvider',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '&[data-field="price"]': {
      gridArea: 'price',
      fontSize: (theme) => ({
        xs: theme.spacing(1),
        lg: theme.spacing(0.875),
      }),
      fontWeight: {
        xs: 600,
        lg: 500,
      },
      textAlign: {
        xs: 'right',
        lg: 'center',
      },
      color: 'neutral.darkest',
    },
  },
  tableLink: {
    color: 'inherit',
  },
  tablePaginationActions: {
    display: 'flex',
    columnGap: 0.5,

    '& .MuiIconButton-root': {
      p: 0.125,
      borderRadius: (theme) => theme.spacing(0.125),
      color: 'neutral.darkest',

      '&:hover, &:focus': {
        color: 'neutral.black',
        backgroundColor: 'neutral.lightest',
      },

      '&.Mui-selected': {
        color: 'neutral.black',
        backgroundColor: 'neutral.light',
      },
      '&.Mui-disabled': {
        color: 'neutral.main',
        opacity: 1,
      },
    },
  },
}

export default styles
