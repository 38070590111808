import baseTheme from '../base'

const MuiButtonBase = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&:focus-visible': {
        outline: `max(3px, 0.08em) solid ${baseTheme.palette.info.dark}`,
        outlineOffset: `min(-3px, -0.08em)`,
      },
    },
  },
}

export default MuiButtonBase
