import baseTheme from '../base'

const MuiPagination = {
  styleOverrides: {
    root: {
      flexShrink: 0,
    },
    ul: {
      '& > li:first-of-type > .MuiPaginationItem-root': {
        marginLeft: 0,
        color: baseTheme.palette.neutral.dark,

        '&.Mui-disabled': {
          color: baseTheme.palette.neutral.main,
        },
      },
      '& > li:last-child > .MuiPaginationItem-root': {
        marginRight: 0,
        color: baseTheme.palette.neutral.dark,

        '&.Mui-disabled': {
          color: baseTheme.palette.neutral.main,
        },
      },
    },
  },
}

export default MuiPagination
