const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    minHeight: (theme) => theme.spacing(12.5),
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    backgroundColor: 'success.lightest',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
    p: 1.5,
    borderTopLeftRadius: (theme) => theme.spacing(1.25),
    borderTopRightRadius: (theme) => theme.spacing(1.25),
  },
  amount: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: (theme) => theme.spacing(2),
    lineHeight: 1.1,
    letterSpacing: '0.03em',
    color: 'neutral.black',

    '& > span': {
      fontFamily: 'Inter',
      fontSize: (theme) => theme.spacing(1.25),
      color: 'success.dark',
    },
  },
  description: {
    pr: 1.5,
    fontSize: (theme) => theme.spacing(0.875),
    fontWeight: 600,
    color: 'neutral.dark',
  },
  expiration: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 0.625,
    px: 1.5,
    pb: 1.5,
    color: 'success.dark',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
      color: 'success.main',
    },
  },
  expirationText: {
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
  },
}

export default styles
