const styles = {
  root: {
    '--root-bgColor': (theme) => theme.palette.neutral.light,
    '--link-color': (theme) => theme.palette.neutral.darkest,
    display: 'flex',
    justifyContent: 'center',
    py: 5,
    backgroundColor: 'var(--root-bgColor)',
  },
  message: {
    width: (theme) => `min(100%, ${theme.spacing(31)})`,
    mx: {
      xs: 'auto',
      xl: '7vw',
      xxl: 3,
    },
  },
  messageHeader: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.25,
    mb: 3.5,
  },
  messageTitle: {
    color: 'neutral.black',
  },
  messageText: {
    width: (theme) => `min(100%, ${theme.spacing(25)})`,
    color: 'neutral.darkest',
  },
  messageWrapActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      xs: 'center',
      xl: 'flex-start',
    },
    rowGap: 1,

    '& .MuiButton-root': {
      width: (theme) => `min(100%, ${theme.spacing(28)})`,
    },
  },
}

export default styles
