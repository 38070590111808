import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import useTexts from '../../../../../texts/useTexts.js'
import { formatPrice } from '../../../../format.js'
import { Picture } from '../../../../components/index.js'
import ScrollToTop from '../../../useScrollToTop.js'
import topUpImageAvif from './computer.avif'
import topUpImageWebp from './computer.webp'
import topUpImagePng from './computer.png'
import styles from './successView.styles.js'

const SuccessView = ({ topUpAmount }) => {
  const texts = useTexts()

  return (
    <ScrollToTop>
      <Box sx={styles.root}>
        <Typography component='h1' sx={styles.description}>
          {texts.getTopUpBalanceSuccessLabel()}
        </Typography>

        <Box sx={styles.wrapPicture}>
          <Typography sx={styles.pictureText}>
            {formatPrice(topUpAmount)}
            <br />
            <span>{texts.getSuccessLabel()}</span>
          </Typography>
          <Picture
            width={415}
            height={328}
            avif={topUpImageAvif}
            webp={topUpImageWebp}
            fallback={topUpImagePng}
          />
        </Box>

        <Box sx={styles.action}>
          <Button color='secondary' component={Link} to='..'>
            {texts.getGoBack()}
          </Button>
        </Box>
      </Box>
    </ScrollToTop>
  )
}

export default SuccessView
