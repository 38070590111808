import { useRouteError } from 'react-router-dom'

import { Session } from '../../../business'
import { useTexts } from '../../../texts'
import { AUTHENTICATED_DEFAULT_ROUTE } from '../../constants'
import { MainPageLayout, MainPageContentLayout } from '../../Layout'
import { Feedback } from '../../components'
import coffeeImageAvif from '../../../assets/images/coffee.avif'
import coffeeImageWebp from '../../../assets/images/coffee.webp'
import coffeeImagePng from '../../../assets/images/coffee.png'

const picture = {
  width: 418,
  height: 367,
  source: {
    avif: coffeeImageAvif,
    webp: coffeeImageWebp,
    fallback: coffeeImagePng,
  },
  styles: {
    maxWidth: '230px',
    height: 'auto',
  },
}

const Error = () => {
  const error = useRouteError()
  const texts = useTexts()
  const isAuthenticated = Session.isAuthenticated()

  const notFoundErrorProps = {
    title: texts.getNotFoundErrorTitle(),
    content: [texts.getNotFoundErrorSubtitle()],
    button: {
      text: texts.getNotFoundErrorCTA(),
      to: AUTHENTICATED_DEFAULT_ROUTE,
    },
    picture,
    isAuthenticated,
  }

  const unexpectedErrorProps = {
    title: texts.getUnexpectedErrorTitle(),
    content: [texts.getUnexpectedErrorSubtitle()],
    button: {
      text: texts.getUnexpectedErrorCTA(),
      to: document.location.pathname,
    },
    secondaryButton: {
      text: texts.getUnexpectedErrorSecondaryButton(),
      to: AUTHENTICATED_DEFAULT_ROUTE,
    },
    picture,
    isAuthenticated,
  }

  const viewProps =
    error.status === 404 ? notFoundErrorProps : unexpectedErrorProps

  return (
    <MainPageLayout>
      <MainPageContentLayout>
        <Feedback {...viewProps} />
      </MainPageContentLayout>
    </MainPageLayout>
  )
}

export default Error
