const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    minHeight: (theme) => theme.spacing(12.5),
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    backgroundColor: 'neutral.white',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
    pt: {
      xs: 2,
      md: 1.5,
    },
    pb: {
      xs: 1.25,
      md: 1.5,
    },
    px: {
      xs: 1.25,
      md: 1.5,
    },
    borderTopLeftRadius: (theme) => theme.spacing(1.25),
    borderTopRightRadius: (theme) => theme.spacing(1.25),
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  amount: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(2),
    }),
    lineHeight: 1.1,
    letterSpacing: '0.03em',
    color: 'neutral.darkest',

    '& > span': {
      fontFamily: 'Inter',
      fontSize: (theme) => theme.spacing(1.25),
      color: 'primary.main',
    },
  },
  icon: {
    display: {
      xs: 'none',
      md: 'flex',
    },
    fontSize: (theme) => theme.spacing(2),
    color: 'neutral.main',
  },
  cta: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 1,
    py: {
      md: 1,
    },
    borderRadius: (theme) => theme.spacing(0, 0, 1.25, 1.25),
  },
  noCta: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    py: 1.5,
    px: {
      xs: 1.25,
      md: 1.5,
    },
    borderRadius: (theme) => theme.spacing(0, 0, 1.25, 1.25),
    backgroundColor: 'neutral.white',
  },
}

export default styles
