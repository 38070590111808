import { useState, useEffect } from 'react'

import { Public } from '..'

const BASE_PAGINATION = {
  limit: 20,
}
const INITIAL_PAGE = 1

const getArticlesFullData = (articles, mediasData) => {
  if (!articles.length || mediasData.isLoading) {
    return articles
  }
  return articles.map((article) => {
    const media = mediasData.data.find((m) => m.id === article.mediaProviderId)
    if (!media) return article
    return {
      ...article,
      mediaName: media.name,
      mediaIconUrl: media.iconSvgUrl,
      mediaImage: media.imageUrl,
      mediaArticlePrice: media.articlePrice,
    }
  })
}

// NOTE: this 'id' param is used as a way to force a re-start of the hook
const useArticlesList = (fetchFunction, id = '') => {
  const mediasData = Public.useMediaProviders()

  const [lastFetchedPage, setLastFetchedPage] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [articles, setArticles] = useState([])
  const [isLastPage, setIsLastPage] = useState(false)
  const [error, setError] = useState(false)

  const validateIsLastPage = (data) => {
    if (data?.totalCount === undefined) return

    const { limit, page } = data.pagination
    if (limit * page >= data.totalCount) {
      setIsLastPage(true)
    }
  }

  // NOTE: the value of the page argument must be a number equal to lastFetchedPage or (lastFetchedPage +1)
  const fetchPage = async (page) => {
    setError(false)
    try {
      setIsFetching(true)
      const pagination = { ...BASE_PAGINATION, page }
      const newArticlesData = await fetchFunction(pagination)
      validateIsLastPage(newArticlesData)
      const newArticlesFullData = getArticlesFullData(
        newArticlesData.data,
        mediasData,
      )

      if (page === lastFetchedPage) {
        setArticles(
          articles.slice(0, -BASE_PAGINATION.limit).concat(newArticlesFullData),
        )
      } else {
        setArticles(articles.concat(newArticlesFullData))
        setLastFetchedPage(page)
      }
      setIsFetching(false)
    } catch (error) {
      setError(true)
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchPage(INITIAL_PAGE)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediasData, fetchFunction])

  return {
    isFetching,
    articles: getArticlesFullData(articles, mediasData),
    isLastPage,
    error,
    fetchNewPage: () => fetchPage(lastFetchedPage + 1),
  }
}

export default useArticlesList
