import PropTypes from 'prop-types'
import { Button } from '@mui/material'

const styles = {
  button: {
    height: 50,
    p: (theme) => ({
      xs: theme.spacing(0.8),
    }),

    '&:hover': {
      backgroundColor: 'neutral.lightest',
    },

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
      objectFit: 'contain',
      display: 'block',
    },
  },
}

const mediaButtonPropTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
}

const MediaButton = ({ onClick, data }) => {
  return (
    <Button
      sx={styles.button}
      key={data.id}
      component='button'
      variant='outlined'
      onClick={() => onClick(data)}
    >
      <img src={data.imageUrl} alt={data.name} />
    </Button>
  )
}

MediaButton.propTypes = mediaButtonPropTypes

export default MediaButton
