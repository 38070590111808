import { CircularProgress } from '../../../components'
import useAccess from '../Access/useAccess'
import AccessSteps from '../Access/Steps'
import LayoutView from './LayoutView'

const OnboardingAccess = () => {
  const { unauthenticationIsConfirmedInLoad, ...stepsProps } = useAccess()

  if (!Boolean(unauthenticationIsConfirmedInLoad))
    return <CircularProgress height='80vh' />

  return (
    <LayoutView>
      <AccessSteps {...stepsProps} />
    </LayoutView>
  )
}

export default OnboardingAccess
