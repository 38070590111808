import { useEffect, useState } from 'react'

import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { openMediaLink, useNavigateWithSearchParams } from '../../utils'
import { Public } from '../../../business'
import { CircularProgress } from '../../components'
import { ACCESS_PARAMS } from '../AccessPages/useSearchParams'
import View from './View'

const getUrlPartnerId = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get(ACCESS_PARAMS.PARTNER_ID)
}

const LandingPartner = () => {
  const partnerId = getUrlPartnerId()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const [partner, setPartner] = useState()

  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
          partnerId,
        },
      }),
    )
    openMediaLink(mediaData.domain)
  }

  const handleClickContinue = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PP_CLICK_LANDING_PARTNER_CONTINUE,
          partnerId,
        },
      }),
    )

    navigateWithSearchParams('/access')
  }

  useEffect(() => {
    ;(async () => {
      const redirectToAccess = () => navigateWithSearchParams('/access')
      if (!partnerId) {
        redirectToAccess()
      } else {
        const partnerData = await Public.getPartnerById(partnerId)
        if (!partnerData || !Boolean(partnerData?.imageUrl)) {
          redirectToAccess()
        } else {
          setPartner(partnerData)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!partner) return <CircularProgress />
  return (
    <View
      openMediaPage={openMediaPage}
      partner={partner}
      onClickContinue={handleClickContinue}
    />
  )
}

export default LandingPartner
