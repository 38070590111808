import baseTheme from '../base'

const MuiMenu = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  styleOverrides: {
    paper: {
      '&.MuiPaper-root': {
        borderRadius: baseTheme.spacing(0.8),
        backgroundColor: baseTheme.palette.neutral.white,
        boxShadow: baseTheme.shadows[4],
        marginTop: -5,
      },
    },
    list: {
      padding: 0,
      minWidth: baseTheme.spacing(12),
    },
  },
}

export default MuiMenu
