import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import { Alert, Chip } from '..'
import styles from './bankCard.styles'

const cardBankPropTypes = {
  bankCardData: PropTypes.shape({
    cardNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    isExpired: PropTypes.bool,
  }),
}

const BankCard = ({ bankCardData }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.card}>
      <Box sx={styles.top}>
        <Chip
          text={
            !bankCardData
              ? texts.getBankCardNotUpdatedLabel()
              : bankCardData.isExpired
              ? texts.getBankCardOutdatedLabel()
              : texts.getBankCardUpdatedLabel()
          }
          variant={
            !bankCardData
              ? 'default'
              : bankCardData.isExpired
              ? 'error'
              : 'success'
          }
        />
        <Box sx={styles.icon}>
          <Icons.Contactless />
        </Box>
      </Box>

      {bankCardData.isExpired ? (
        <Alert variant='error' text={texts.getBankCardOutdatedMessageLabel()} />
      ) : null}

      <Box sx={styles.bottom}>
        {!bankCardData ? (
          <Typography
            component='span'
            sx={styles.cardData}
          >{`•••• •••• •••• ••••`}</Typography>
        ) : (
          <>
            <Typography component='span' sx={styles.cardData}>
              {`•••• •••• •••• ${bankCardData.cardNumber?.slice(-4)}`}
            </Typography>
            <Typography component='span' sx={styles.cardData}>
              {bankCardData.expiryDate}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}

BankCard.propTypes = cardBankPropTypes

export default BankCard
