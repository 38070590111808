import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import EVENTS_NAMES from '../../../../../analytics/eventsNames.json'
import { ApplePay } from '../../../../../business'
import { CircularProgress } from '../../../../components/index'

import { ModalWithPayperFooter } from '../../../../components/Modal'
import ApplePayTopUp from './ApplePayTopUp'
import BankCardStep from './BankCardStep'
import ErrorStep from './ErrorStep'

const STEPS = {
  APPLE_PAY_TOP_UP: 'APPLE_PAY_TOP_UP',
  BANKCARD_REGISTRATION: 'BANKCARD_REGISTRATION',
  TOP_UP: 'TOP_UP',
  ERROR: 'ERROR',
}

const STEP_MODAL_PROPS = {
  APPLE_PAY_TOP_UP: {
    title: (texts) => texts.getApplePayTopUpTitle(),
    background: 'gradient',
    sx: {},
  },
  BANKCARD_REGISTRATION: {
    title: (texts) => texts.getIntroBankCardTitleLabel(),
    background: 'solid',
    sx: {},
  },
  ERROR: {
    title: (texts) => texts.getPurchaseErrorTitle2(),
    description: (texts) => texts.getTopUpErrorSubtitle(),
    background: 'gradient',
    sx: {},
  },
}

const paymentRegistrationAndTopUpFlowPropTypes = {
  onFinishWithSuccess: PropTypes.func.isRequired,
  onFinishWithError: PropTypes.func.isRequired,
  articlePrice: PropTypes.string.isRequired,
  userSub: PropTypes.string.isRequired,
  mediaName: PropTypes.string,
}

const PaymentRegistrationAndTopUpFlow = ({
  onFinishWithSuccess,
  onFinishWithError,
  articlePrice,
  userSub,
  mediaName,
}) => {
  const [activeStep, setActiveStep] = useState()
  const [stepOriginatingError, setStepOriginatingError] = useState()

  const onCloseModal = () => {
    if (
      activeStep === STEPS.APPLE_PAY_TOP_UP ||
      activeStep === STEPS.BANKCARD_REGISTRATION ||
      (activeStep === STEPS.ERROR &&
        stepOriginatingError === STEPS.BANKCARD_REGISTRATION)
    ) {
      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPrivateEvent', {
          detail: {
            name: EVENTS_NAMES.PM_CLICK_CANCEL,
          },
        }),
      )

      onFinishWithError()
    } else {
      onFinishWithSuccess()
    }
  }
  useEffect(() => {
    ;(async () => {
      if (await ApplePay.hasActiveCard()) {
        setActiveStep(STEPS.APPLE_PAY_TOP_UP)
      } else {
        setActiveStep(STEPS.BANKCARD_REGISTRATION)
      }
    })()
  }, [])

  if (!Boolean(activeStep)) {
    return <CircularProgress height='80vh' />
  }

  return (
    <ModalWithPayperFooter
      onCancel={onCloseModal}
      mediaName={mediaName}
      {...STEP_MODAL_PROPS[activeStep]}
    >
      {activeStep === STEPS.APPLE_PAY_TOP_UP ? (
        <ApplePayTopUp
          onClickIntroBankCard={() =>
            setActiveStep(STEPS.BANKCARD_REGISTRATION)
          }
          onContinue={onFinishWithSuccess}
        />
      ) : activeStep === STEPS.BANKCARD_REGISTRATION ? (
        <BankCardStep
          sub={userSub}
          articlePrice={articlePrice}
          onContinue={onFinishWithSuccess}
        />
      ) : activeStep === STEPS.ERROR ? (
        <ErrorStep
          onRetry={() => {
            setActiveStep(stepOriginatingError)
            setStepOriginatingError()
          }}
          onCancelProcess={onFinishWithError}
        />
      ) : null}
    </ModalWithPayperFooter>
  )
}

PaymentRegistrationAndTopUpFlow.propTypes =
  paymentRegistrationAndTopUpFlowPropTypes

export default PaymentRegistrationAndTopUpFlow
