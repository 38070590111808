import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'

import { PageBlock } from '../../../Layout'
import { Public } from '../../../../business'
import { useTexts } from '../../../../texts'

const styles = {
  title: {
    textAlign: {
      md: 'center',
    },
  },
  buttonsContainer: (theme) => ({
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    px: (theme) => ({
      xs: theme.spacing(0),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    gridRowGap: (theme) => theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: (theme) => theme.spacing(2),
      '& :last-child': {
        gridColumn: '2 / 3',
      },
    },
  }),
  button: {
    height: 50,

    p: (theme) => ({
      xs: theme.spacing(0.5),
      lg: theme.spacing(1),
    }),

    '&:hover': {
      backgroundColor: 'neutral.lightest',
    },
    '& span': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}

const Sections = () => {
  const texts = useTexts()
  const navigate = useNavigate()

  const sections = Public.useArticlesSections()

  const navigateToSection = (sectionId) => {
    navigate(`section/${sectionId}`)
  }

  if (sections.isLoading || !sections.data) {
    return null
  }
  return (
    <PageBlock title={texts.getSectionsTitle()}>
      <Box sx={styles.buttonsContainer} id='section_buttons'>
        {Object.entries(sections.data).map(([sectionKey, sectionLabel]) => (
          <Button
            key={sectionKey}
            variant='outlined'
            onClick={() => navigateToSection(sectionKey)}
            sx={styles.button}
          >
            <Typography variant='title4'>{sectionLabel}</Typography>
          </Button>
        ))}
      </Box>
    </PageBlock>
  )
}

export default Sections
