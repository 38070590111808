import PropTypes from 'prop-types'
import { useTheme, Box } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const styles = {
  '& .swiper': {
    width: '100%',
    height: '100%',
  },
  '& .swiper-slide': {
    width: '80%',
    height: '100%',
  },
}

const CarouselPropTypes = {
  slides: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  withNavigation: PropTypes.bool,
  onSlideChange: PropTypes.func,
}

const Carousel = ({ slides, withNavigation, onSlideChange }) => {
  const theme = useTheme()

  const modules = [Pagination]
  if (withNavigation) {
    modules.push(Navigation)
  }

  return (
    <Box sx={styles}>
      <Swiper
        slidesPerView='auto'
        spaceBetween={16}
        pagination={{ clickable: true }}
        navigation={withNavigation}
        modules={modules}
        onSlideChange={onSlideChange}
        style={{
          '--swiper-theme-color': theme.palette.primary.main,
        }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={`step_${i}`}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

Carousel.propTypes = CarouselPropTypes

export default Carousel
