import { useLayoutEffect } from 'react'
import { Box } from '@mui/material'

import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { useTexts } from '../../../texts'
import { openMediaLink } from '../../utils'
import { AssociatedMedia } from '../../components'
import { ASSOCIATED_MEDIAS_DATA } from '../../constants'
import { PageContent, PageBlock } from '../../Layout'

const styles = {
  appBar: {
    width: '100%',
    backgroundColor: 'primary.main',
  },
}

const Home = () => {
  const texts = useTexts()

  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
        },
      }),
    )
    openMediaLink(mediaData.domain)
  }

  useLayoutEffect(() => {
    const appBarHeight = document
      .getElementById('public-header')
      ?.getBoundingClientRect()?.height

    if (Boolean(appBarHeight)) {
      document.getElementById('header-public-background').style.height =
        appBarHeight.toString() + 'px'
    }
  }, [])

  return (
    <>
      <Box sx={styles.appBar} id='header-public-background' />
      <PageContent>
        <PageBlock title={texts.getAssociatedMediaPublicTitle()}>
          <AssociatedMedia
            medias={ASSOCIATED_MEDIAS_DATA}
            onClickMedia={openMediaPage}
          />
        </PageBlock>
      </PageContent>
    </>
  )
}

export default Home
