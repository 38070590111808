import { useState } from 'react'
import {
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import {
  FormSkinWrapper,
  Alert,
  TextField,
  HelperText,
} from '../../../components'
import { Icons } from '../../../../assets'
import { useTexts } from '../../../../texts'
import { Auth } from '../../../../business'
import { useFormV2, composeValidators, validators } from '../../../../forms'
import { isValidPassword, isValidCode } from '../../../../business/validate'
import styles from './forgottenPassword.styles'

const FORGOTTEN_PASSWORD_ENTER_CODE_AND_PASSWORD_FORM_ID =
  EVENTS_NAMES.PA_FORM_SUBMIT_FP_CONFIRM_PASSWORD

const useEnterCodeAndNewPasswordForm = (email) => {
  const texts = useTexts()

  const FORM_VALIDATION_CONFIG = {
    verificationCode: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidCode, texts.getErrorCode()],
    ]),
    newPassword: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
  }

  const submitForm = async (formValues) =>
    await Auth.confirmForgottenPassword(
      email,
      formValues.verificationCode,
      formValues.newPassword,
    )

  return useFormV2(
    FORGOTTEN_PASSWORD_ENTER_CODE_AND_PASSWORD_FORM_ID,
    submitForm,
    FORM_VALIDATION_CONFIG,
  )
}

const EnterCodeAndNewPasswordStep = ({
  isDarkMode,
  email,
  onClickResendCode,
  onSuccess,
}) => {
  const texts = useTexts()

  const [showNewPassword, setShowNewPassword] = useState(false)

  const { status, isSubmitSuccess, submitForm } =
    useEnterCodeAndNewPasswordForm(email)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  if (isSubmitSuccess()) {
    onSuccess()
  }

  return (
    <form
      id={FORGOTTEN_PASSWORD_ENTER_CODE_AND_PASSWORD_FORM_ID}
      onSubmit={handleSubmit}
      noValidate
    >
      <FormSkinWrapper
        skin={isDarkMode ? 'dark' : 'light'}
        sx={styles.wrapFields}
      >
        <TextField
          id='verificationCode'
          name='verificationCode'
          label={texts.getForgottenPasswordResetCodeLabel()}
          type={'text'}
          helperText={status.fieldErrors.verificationCode}
          error={Boolean(status.fieldErrors.verificationCode)}
          required
        />

        <TextField
          id='newPassword'
          name='newPassword'
          label={texts.getUpdatePersonalDataNewPasswordLabel()}
          type={showNewPassword ? 'text' : 'password'}
          helperText={status.fieldErrors.newPassword}
          error={Boolean(status.fieldErrors.newPassword)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icons.LockClosed />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                onClick={() =>
                  setShowNewPassword((showPassword) => !showPassword)
                }
              >
                <IconButton aria-label={texts.getTogglePasswordAction()}>
                  {showNewPassword ? <Icons.EyeFilled /> : <Icons.EyeClosed />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
        <Box mt={-0.5} mb={0.5}>
          <HelperText text={texts.getRegisterPasswordRequirementsLabel()} />
        </Box>

        {Boolean(status.formError) && (
          <Alert variant='error' text={status.formError} />
        )}
        <Box sx={styles.actions}>
          <LoadingButton
            type='submit'
            color={isDarkMode ? undefined : 'secondary'}
            loading={status.isSubmitting}
            disabled={status.isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
          >
            {texts.getForgottenPasswordSaveNewPasswordAction()}
          </LoadingButton>
          <Button
            size='small'
            variant='text'
            color={isDarkMode ? 'secondary' : undefined}
            onClick={onClickResendCode}
            fullWidth
          >
            {texts.getForgottenPasswordResendCodeAction()}
          </Button>
        </Box>
      </FormSkinWrapper>
    </form>
  )
}
export default EnterCodeAndNewPasswordStep
