const styles = {
  dark: {
    '--root-bgColor': (theme) => theme.palette.neutral.black,
    '--link-color': (theme) => theme.palette.neutral.white,
  },
  light: {
    '--root-bgColor': (theme) => theme.palette.neutral.light,
    '--link-color': (theme) => theme.palette.neutral.darkest,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    py: { xs: 3, md: 5 },
    backgroundColor: 'var(--root-bgColor, transparent)',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: (theme) => ({
      xs: '1fr',
      xl: '1fr 1fr',
      xxl: `${theme.spacing(33.75)} ${theme.spacing(35.3125)}`,
    }),
    justifyContent: 'center',
    rowGap: {
      xs: 2,
      md: 3.25,
      lg: 5.25,
      xl: 9,
    },
    columnGap: {
      lg: '5%',
      xxl: '10%',
    },
    width: (theme) => ({
      xs: `min(100%, ${theme.spacing(90)})`,
      lg: `min(100%, ${theme.spacing(90)})`,
    }),
    px: {
      md: 0.5,
      lg: 3,
    },
  },
  info: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
      xl: 'column',
    },
    alignItems: {
      md: 'end',
      lg: 'start',
    },
    rowGap: {
      xs: 1,
      lg: 3.5,
    },
    columnGap: { md: 1.25 },
    position: {
      md: 'relative',
    },
  },
  wrapText: {
    width: (theme) => ({
      md: `min(100%, ${theme.spacing(28)})`,
    }),
  },
  heading: {
    maxWidth: '13ch',
    fontSize: (theme) => ({
      md: theme.spacing(2),
      lg: theme.spacing(2.625),
    }),
    color: 'neutral.white',
  },
  slot: {
    position: {
      lg: 'relative',
    },
  },
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
}

export default styles
