import theme from '../../../theme'

const styles = {
  invisible: {
    display: {
      xs: 'none',
      md: 'inherit',
    },
    color: 'transparent',
  },
  cardActionArea: {
    '--image-size': theme.spacing(5.875),
    '--title-mb': theme.spacing(0.25),

    '&:hover, &:focus, &:active, &:focus-visible, &:focus-within, &:visited, &:target':
      {
        backgroundColor: 'neutral.white',
      },
  },
  card: {
    borderRadius: 0,
    width: '100%',
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'neutral.white',
    },

    // WARNING: This padding is synced with articles sections' titles padding.
    // Keep in mind that any updates here will affect these paddings (search for 'articleCardStyles.card.px' occurrencies)
    px: {
      xs: 1,
      md: 1.5,
    },
    py: 1.25,

    display: 'flex',
    flexDirection: 'row',
    columnGap: 1,
    '& > div:first-of-type': {
      flexGrow: 2,
    },
  },
  displayVertical: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  regularImg: {
    '& > img': {
      height: {
        xs: 70,
        md: 'var(--image-size)',
      },
      width: {
        xs: 70,
        md: 'var(--image-size)',
      },
      objectFit: 'cover',
    },
  },
  title: {
    mb: (theme) => ({
      xs: theme.spacing(0.375),
      md: 'var(--title-mb)',
    }),
  },
  clampedTitle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  description: {
    mb: (theme) => theme.spacing(0.3125),
  },
  mediaData: {
    pb: (theme) => theme.spacing(0.4),
    color: 'neutral.main',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 0.5,
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    color: 'neutral.main',

    '& > .separator': {
      mx: 0.5,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: 'neutral.main',
    },
  },
  freeLabel: {
    color: 'info.dark',
    fontWeight: 700,
  },
}

export default styles
