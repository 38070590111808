const focusStyles = {
  outline: (theme) => `max(2px, 0.08em) solid ${theme.palette.primary.main}`,
  borderRadius: (theme) => theme.spacing(0.125),
}

const styles = {
  dark: {
    '--default--fill': (theme) => theme.palette.neutral.main,
    '--active--fill': (theme) => theme.palette.neutral.lightest,
    '--active-check--stroke': (theme) => theme.palette.neutral.darkest,
    color: 'neutral.light',

    '& b': {
      fontWeight: 'inherit',
      color: 'info.main',
    },

    '&:hover:not(.Mui-error)': {
      '--default--stroke': (theme) => theme.palette.neutral.white,

      '& svg': {
        filter: (theme) =>
          `drop-shadow(0px 1px 0px ${theme.palette.neutral.dark})`,
      },
    },

    '& input:focus-visible + svg': { ...focusStyles },

    '&.Mui-error': {
      '--default--fill': (theme) => theme.palette.error.light,
      color: 'error.light',
    },
  },
  light: {
    '--default--fill': (theme) => theme.palette.neutral.light,
    '--active--fill': (theme) => theme.palette.neutral.black,
    '--active-check--stroke': (theme) => theme.palette.neutral.white,
    color: (theme) => theme.palette.neutral.dark,

    '&:hover:not(.Mui-error)': {
      '--default--stroke': (theme) => theme.palette.neutral.main,

      '& svg': {
        filter: (theme) =>
          `drop-shadow(0px 1px 0px ${theme.palette.neutral.light})`,
      },
    },

    '& input:focus-visible + svg': { ...focusStyles },

    '&.Mui-error': {
      '--default--fill': (theme) => theme.palette.error.light,
      color: 'error.dark',
    },
  },
  small: {
    '& > .MuiTypography-root': {
      fontSize: (theme) => theme.spacing(0.75),
    },
  },
  medium: {},
}

export default styles
