const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
    mb: 1.5,
  },
}

export default styles
