import { Box } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { openMediaLink } from '../../utils'
import { User } from '../../../business'
import { MainPageContentLayout } from '../../Layout'
import { ArticlesList, ARTICLES_DATE_TYPE, Tabs } from '../../components'

const styles = {
  layout: {
    my: 0,
    mb: {
      xs: 2,
      md: 3,
    },
    mt: 0,
  },
  root: {
    flex: 1,
    mt: { md: 2 },
  },
}

const TABS = {
  READ: 'read',
  FAVORITE: 'favorite',
}

const ReadArticles = ({ onClickArticle }) => {
  const readArticlesData = User.useArticlesList(User.getArticlesViewedByUser)

  return (
    <ArticlesList
      onClickArticle={onClickArticle}
      dateType={ARTICLES_DATE_TYPE.READ}
      {...readArticlesData}
    />
  )
}

const FavoriteArticles = ({ onClickArticle }) => {
  const favoriteArticlesData = User.useArticlesList(
    User.getUserFavoriteArticles,
  )

  return (
    <ArticlesList onClickArticle={onClickArticle} {...favoriteArticlesData} />
  )
}

const Library = () => {
  const texts = useTexts()

  const handleAnalyticsEventOnClickTab = (tabName) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_TAB,
          tabName,
        },
      }),
    )
  }

  const handleOpenReadArticle = (article) =>
    handleOpenArticle(article, TABS.READ)
  const handleOpenFavoriteArticle = (article) =>
    handleOpenArticle(article, TABS.FAVORITE)

  const handleOpenArticle = (article, tabName) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_ARTICLE,
          interface: `tab_${tabName}`,
          articleId: article.articleId,
          mediaId: article.mediaProviderId,
        },
      }),
    )
    openMediaLink(article.url)
  }

  return (
    <MainPageContentLayout sx={styles.layout}>
      <Box sx={styles.root} id='library-page'>
        <Tabs
          ariaLabel={texts.getHomeTabsAriaLabel()}
          tabs={[
            {
              name: TABS.READ,
              label: texts.read(),
              icon: <Icons.Eye />,
              content: <ReadArticles onClickArticle={handleOpenReadArticle} />,
            },
            {
              name: TABS.FAVORITE,
              label: texts.favorite(),
              icon: <Icons.Star />,
              content: (
                <FavoriteArticles onClickArticle={handleOpenFavoriteArticle} />
              ),
            },
          ]}
          onClickTab={handleAnalyticsEventOnClickTab}
        />
      </Box>
    </MainPageContentLayout>
  )
}

export default Library
