import { Box, Container, Link as MUILink } from '@mui/material'

import { Session } from '../../../business'
import { useTexts } from '../../../texts'

import styles from './policiesLinks.styles'

const linkPropsForNewTab = {
  target: '_blank',
  rel: 'noopener',
}
const linkPropsForExternalLink = {
  target: '_blank',
  rel: 'noreferrer',
}

const PoliciesLinks = ({ component = 'aside' }) => {
  const texts = useTexts()

  const isAuthenticated = Session.isAuthenticated()
  const linkPropsForInternalLink = !isAuthenticated ? linkPropsForNewTab : {}

  const LINKS = [
    {
      name: texts.getPrivacyPolicyLabel(),
      href: 'https://www.bepayper.com/politica-de-privacidad',
      props: linkPropsForExternalLink,
    },
    {
      name: texts.getServiceTermsLabel(),
      href: 'https://www.bepayper.com/terminos-y-condiciones',
      props: linkPropsForExternalLink,
    },
    {
      name: texts.getLegalNoticeLabel(),
      href: 'https://www.bepayper.com/aviso-legal',
      props: linkPropsForExternalLink,
    },
    {
      name: texts.getCookiesTitle(),
      href: 'https://www.bepayper.com/cookies',
      props: linkPropsForInternalLink,
    },
  ]

  return (
    <Box component={component} sx={styles.root}>
      <Container>
        <Box component='ul' sx={styles.list}>
          {LINKS.map((link, idx) => (
            <Box key={idx} component='li' sx={styles.item}>
              <MUILink href={link.href} sx={styles.link} {...link.props}>
                {link.name}
              </MUILink>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default PoliciesLinks
