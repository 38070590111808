import baseTheme from '../base'

const MuiListItemText = {
  styleOverrides: {
    root: {
      color: baseTheme.palette.neutral.darkest,
    },
    primary: {
      fontSize: baseTheme.spacing(0.8125),
      fontWeight: 500,

      [baseTheme.breakpoints.up('md')]: {
        fontSize: baseTheme.spacing(0.875),
      },
    },
  },
}

export default MuiListItemText
