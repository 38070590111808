import { useEffect, useState } from 'react'

import { useTexts } from '../../../texts'
import { BankCardOrder, Session } from '../../../business'
import CircularProgress from '../CircularProgress'
import BankCardForm from './BankCardForm'
import Preauthorization from './Preauthorization'
import ChallengeV1 from './ChallengeV1'
import ChallengeV2 from './ChallengeV2'

const INITIALIZATION = Symbol('initialization')
const BANK_CARD_DATA = Symbol('bank-card-data')
const PREAUTHORIZATION = Symbol('preauthorization')
const CHALLENGE_V1 = Symbol('challenge-v1')
const CHALLENGE_V2 = Symbol('challenge-v2')

const BankCardRegistration = ({
  onSuccess,
  onError,
  sub,
  customCtaConfig,
  renderBankCardCustomContent = () => {},
}) => {
  const [activeSection, setActiveSection] = useState({ id: INITIALIZATION })
  const [error, setError] = useState('')
  const texts = useTexts()

  const handleError = (customErrorMessage) => {
    setActiveSection({ id: INITIALIZATION })
    const errorMessage = customErrorMessage ?? texts.getRegisterBankCardError()
    setError(errorMessage)
    onError(errorMessage)
  }

  const handlePreauthResult = (preauthResult, operationId, orderId) => {
    const isThreeDSV2 =
      preauthResult.threeDSProtocolVersion === '2.2.0' ||
      preauthResult.threeDSProtocolVersion === '2.1.0'

    if (isThreeDSV2) {
      const { threeDSServerTransID } = preauthResult
      setActiveSection({
        id: CHALLENGE_V2,
        data: { threeDSServerTransID },
      })
    } else {
      setActiveSection({
        id: CHALLENGE_V1,
        data: { operationId, orderId },
      })
    }
  }

  const handleBankCardSubmit = (operationId, orderId) => {
    setActiveSection({
      id: PREAUTHORIZATION,
      data: { operationId, orderId },
    })
  }

  useEffect(() => {
    if (activeSection.id === INITIALIZATION) {
      ;(async () => {
        // NOTE: for new users tokens are not stored and sub is passed through props
        const userSub = sub ?? Session.getUserSub()
        if (!Boolean(userSub)) {
          throw new Error('BankCardRegistration needs user-sub')
        }

        const orderData = await BankCardOrder.requestOrderData(userSub)
        setActiveSection({ id: BANK_CARD_DATA, data: { orderData } })
      })()
      return
    }
  }, [activeSection, sub])

  return (
    <>
      {activeSection.id === BANK_CARD_DATA ? (
        <BankCardForm
          error={error}
          onError={onError}
          customCtaConfig={customCtaConfig}
          onBankCardSubmit={handleBankCardSubmit}
          {...activeSection.data}
          renderCustomContent={renderBankCardCustomContent}
        />
      ) : activeSection.id === PREAUTHORIZATION ? (
        <Preauthorization
          onError={handleError}
          onPreauthorizationFinish={handlePreauthResult}
          {...activeSection.data}
        />
      ) : activeSection.id === CHALLENGE_V1 ? (
        <ChallengeV1
          onError={handleError}
          onSuccess={onSuccess}
          {...activeSection.data}
        />
      ) : activeSection.id === CHALLENGE_V2 ? (
        <ChallengeV2
          onError={handleError}
          onSuccess={onSuccess}
          {...activeSection.data}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

export default BankCardRegistration
