import Modal from './Modal'
import PoweredByPayper from './PoweredByPayper'

const ModalWithPayperFooter = (props) => {
  const { mediaName, ...restProps } = props
  return (
    <Modal
      {...restProps}
      renderFooter={() => <PoweredByPayper mediaName={mediaName} />}
    />
  )
}

export default ModalWithPayperFooter
