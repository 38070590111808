import theme from '../../../theme'

const styles = {
  topUp: {
    // NOTE: These styles were applied in an heterogeneous way, so they are keep for reference
    // width: (theme) => `min(100%, ${theme.spacing(20)})`,
    // mx: 'auto',
  },
  fieldset: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.2fr 1fr',
    columnGap: 0.5,
    p: 0,
    m: 0,
    border: 0,
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      bottom: '0%',
      backgroundColor: 'info.lightest',
    },
  },
  wrapOption: {
    position: 'relative',

    '&:nth-of-type(2) input + span': {
      transform: (theme) => `translateY(${theme.spacing(-0.25)})`,
    },
  },
  option: {
    ...theme.typography.h4,
    flex: 1,
    display: 'grid',
    gridTemplateRows: (theme) => `${theme.spacing(0.625)} auto`,
    rowGap: 0.5,
    pt: 0.75,
    pb: 2,
    px: 0.75,
    color: 'neutral.dark',
    textAlign: 'center',
    border: `1px solid ${theme.palette.neutral.light}`,
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 3,
    backgroundColor: 'neutral.white',
    position: 'relative',

    '&:has(input:checked)': {
      boxShadow: 1,
      outline: (theme) =>
        `max(2px, 0.08em) solid ${theme.palette.primary.main}`,

      '&:focus-within': {
        outline: (theme) =>
          `max(3px, 0.08em) solid ${theme.palette.primary.main}`,
      },
    },

    '&:focus-within': {
      outline: (theme) =>
        `max(2px, 0.08em) solid ${theme.palette.primary.main}`,
    },

    '& > input': {
      display: 'grid',
      placeContent: 'center',
      appearance: 'none',
      justifySelf: 'end',
      width: '1em',
      height: '1em',
      m: 0,
      fontSize: (theme) => theme.spacing(0.625),

      color: 'neutral.light',
      border: '0.1em solid currentColor',
      borderRadius: '50%',
      backgroundColor: 'neutral.lightest',

      '&::before': {
        content: '""',
        width: '1em',
        height: '1em',
        borderRadius: '50%',
        color: 'primary.dark',
        border: '0.1em solid currentColor',
        backgroundColor: 'primary.main',
        transform: 'scale(0)',
        transition: '120ms transform ease-in-out',
      },
      '&:checked::before': {
        transform: 'scale(1)',
      },

      '&:focus': {
        outline: 'none',
      },
    },
  },
  // NOTE: These styles are needed at the time of this coding
  // since Firefox does not support :has() selector
  optionSelected: {
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    outline: (theme) => `max(2px, 0.08em) solid ${theme.palette.primary.main}`,

    '&:focus-within': {
      outline: (theme) =>
        `max(3px, 0.08em) solid ${theme.palette.primary.main}`,
    },
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 0.625,
    py: 0.25,
    px: 1,
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '0.1em',
    color: 'neutral.white',
    borderRadius: (theme) => theme.spacing(2),
    boxShadow: 3,
    backgroundColor: 'info.main',
    position: 'absolute',
    left: '50%',
    bottom: (theme) => theme.spacing(0.5),
    zIndex: 1,
    transform: 'translateX(-50%)',
    pointerEvents: 'none',
  },
}

export default styles
