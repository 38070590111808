import PropTypes from 'prop-types'
import { NAVIGATION_ITEMS } from './constants'

export const AssociatedMediaData = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  domain: PropTypes.string,
})

export const Article = PropTypes.shape({
  articleId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  mediaProviderId: PropTypes.string,
  image: PropTypes.string,
  mediaName: PropTypes.string,
  mediaIconUrl: PropTypes.string,
})

export const ARTICLE_ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

export const ArticlesSection = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(Article),
})

export const Partner = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  configuration: PropTypes.object,
})

export const NavigationItemName = PropTypes.oneOf(
  Object.values(NAVIGATION_ITEMS),
)
export const NavigationActions = PropTypes.arrayOf(
  PropTypes.shape({
    name: NavigationItemName.isRequired,
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
)
