import { useLocation, Navigate } from 'react-router-dom'

import { Session } from '../business'
import { AUTHENTICATED_DEFAULT_ROUTE } from './constants'

const RequireNotAuthenticated = ({ children }) => {
  let location = useLocation()

  if (Session.isAuthenticated()) {
    return (
      <Navigate
        to={AUTHENTICATED_DEFAULT_ROUTE}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}

export default RequireNotAuthenticated
