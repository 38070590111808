import baseTheme from '../base'

const appleIconStyles = {
  '& .MuiButton-startIcon': {
    marginLeft: 0,

    '& > *:nth-of-type(1)': {
      fontSize: baseTheme.spacing(1.25),
    },
  },

  '&.Mui-disabled': {
    color: baseTheme.palette.neutral.lightest,
    backgroundColor: baseTheme.palette.neutral.dark,
  },
}

const MuiButton = {
  defaultProps: {
    color: 'inherit',
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      ...baseTheme.typography.body2,
      padding: baseTheme.spacing(1.1, 2),
      fontWeight: 500,
      textTransform: 'none',
      boxShadow: baseTheme.shadows[0],

      '& .MuiButton-startIcon': {
        marginRight: baseTheme.spacing(1),

        '& > *:nth-of-type(1)': {
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: baseTheme.spacing(1.5),
          },
        },
      },
      '& .MuiButton-endIcon': {
        marginLeft: baseTheme.spacing(1),

        '& > *:nth-of-type(1)': {
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: baseTheme.spacing(1.5),
          },
        },
      },

      [baseTheme.breakpoints.up('md')]: {
        padding: baseTheme.spacing(1.25, 2),
      },
    },
    sizeSmall: {
      padding: baseTheme.spacing(1.1, 1.5),

      [baseTheme.breakpoints.up('md')]: {
        padding: baseTheme.spacing(1.1, 1.5),
      },

      '&.MuiButtonBase-root': {
        [baseTheme.breakpoints.up('lg')]: {
          fontSize: baseTheme.spacing(0.875),
          padding: baseTheme.spacing(1.1, 1.5),
        },
      },
    },
    text: {
      color: baseTheme.palette.neutral.black,

      '&:hover': {
        color: baseTheme.palette.neutral.darkest,
        backgroundColor: baseTheme.palette.neutral.lightest,
      },
      '&:active': {
        color: baseTheme.palette.neutral.dark,
        backgroundColor: 'transparent',
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral.main,
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.neutral.darkest,
      },
    },
    textSecondary: {
      color: baseTheme.palette.neutral.white,

      '&:hover': {
        color: baseTheme.palette.neutral.darkest,
        backgroundColor: baseTheme.palette.neutral.lightest,
      },
      '&:active': {
        color: baseTheme.palette.neutral.black,
        backgroundColor: baseTheme.palette.neutral.light,
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral.main,
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.neutral.lightest,
      },
    },
    contained: {
      '&:hover': {
        boxShadow: baseTheme.shadows[0],
      },
    },
    containedInherit: {
      color: baseTheme.palette.neutral.dark,
      backgroundColor: baseTheme.palette.neutral.light,

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral.lightest,
      },
      '&:active': {
        color: baseTheme.palette.neutral.dark,
        backgroundColor: baseTheme.palette.neutral.lightest,
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral.main,
        backgroundColor: baseTheme.palette.neutral.lightest,
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.getContrastText(
          baseTheme.palette.neutral.lightest,
        ),
      },
    },
    containedPrimary: {
      color: baseTheme.palette.neutral.black,
      backgroundColor: baseTheme.palette.primary.main,

      '&:hover': {
        color: baseTheme.palette.neutral.darkest,
        backgroundColor: baseTheme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: baseTheme.palette.neutral.light,
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.getContrastText(
          baseTheme.palette.neutral.light,
        ),
      },
    },
    containedSecondary: {
      color: baseTheme.palette.neutral.white,
      backgroundColor: baseTheme.palette.neutral.black,

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral.darkest,
      },
      '&:active': {
        backgroundColor: baseTheme.palette.neutral.dark,
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral.lightest,
        backgroundColor: baseTheme.palette.neutral.dark,
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.getContrastText(
          baseTheme.palette.neutral.dark,
        ),
      },

      '& b': {
        display: 'inline-block',
        width: baseTheme.spacing(1),
        marginLeft: baseTheme.spacing(0.125),
        fontWeight: 'normal',
        color: baseTheme.palette.primary.main,
      },
      '&.MuiLoadingButton-loading b': {
        color: 'transparent',
      },
    },
    containedTertiary: {
      color: baseTheme.palette.neutral.darkest,
      backgroundColor: baseTheme.palette.neutral.white,
    },
    containedDefault: {
      fontWeight: 400,
      backgroundColor: baseTheme.palette.neutral.light,

      '& b': {
        fontWeight: 'inherit',
        color: baseTheme.palette.primary.dark,
      },
    },
    google: {
      paddingTop: baseTheme.spacing(0.5625),
      paddingBottom: baseTheme.spacing(0.5625),
      minHeight: baseTheme.spacing(3.5),
      maxHeight: baseTheme.spacing(3.5),
      fontFamily: 'Roboto',
      lineHeight: 1.15,
      color: baseTheme.palette.neutral.white,
      backgroundColor: '#4285F4',

      '&:hover': {
        backgroundColor: '#3367D6',
      },
      '&.Mui-disabled': {
        color: baseTheme.palette.neutral.white,
        opacity: 0.85,
      },
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: baseTheme.spacing(0.6875),

        '& > *:nth-of-type(1)': {
          fontSize: baseTheme.spacing(2.375),
        },
      },
    },
    appleLight: {
      color: baseTheme.palette.neutral.dark,
      backgroundColor: baseTheme.palette.neutral.white,

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral.lightest,
      },
      '&:active': {
        backgroundColor: baseTheme.palette.neutral.lightest,
      },

      ...appleIconStyles,
    },
    appleDark: {
      color: baseTheme.palette.neutral.white,
      backgroundColor: baseTheme.palette.neutral.black,

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral.darkest,
      },
      '&:active': {
        backgroundColor: baseTheme.palette.neutral.dark,
      },

      ...appleIconStyles,
    },
  },
}

export default MuiButton
