import PropTypes from 'prop-types'
import {
  Card,
  Box,
  Typography,
  Skeleton,
  CardActionArea,
  useMediaQuery,
} from '@mui/material'

import * as PT from '../../propTypes'
import theme from '../../../theme'
import { formatFullLongDate, formatPrice } from '../../format'
import { useTexts } from '../../../texts'
import { Picture } from '../index'
import styles from './articleCard.styles'

const formattedPrice = (price) =>
  price === undefined ? '' : formatPrice(price)

export const ARTICLES_DATE_TYPE = {
  READ: 'read',
  PUBLISHED: 'published',
}

const MediaInfo = ({ article }) => (
  <Box sx={styles.mediaData}>
    {Boolean(article?.mediaIconUrl) && (
      <Picture
        width={20}
        height={20}
        fallback={article.mediaIconUrl}
        alt='icono medio'
      />
    )}
    {Boolean(article?.mediaName) && (
      <Typography variant='p'>{article.mediaName}</Typography>
    )}
  </Box>
)

const articleCardV2PropTypes = {
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  onClick: PropTypes.func,
  dateType: PropTypes.oneOf(Object.values(ARTICLES_DATE_TYPE)),
  showPriceLabel: PropTypes.bool,
}

export const ArticleCardV2 = ({
  isSkeleton,
  article,
  onClick,
  dateType = ARTICLES_DATE_TYPE.PUBLISHED,
  showPriceLabel = false,
}) => {
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  const texts = useTexts()

  const dateLabel =
    dateType === ARTICLES_DATE_TYPE.PUBLISHED
      ? texts.publishedDate()
      : texts.readDate()

  const priceLabel =
    article.isPremium === undefined
      ? null
      : article.isPremium === 0
      ? texts.free()
      : formattedPrice(article?.mediaArticlePrice)

  return (
    <CardActionArea
      onClick={() => onClick(article?.url)}
      sx={styles.cardActionArea}
    >
      <Card variant='plain' sx={styles.card}>
        <Box sx={styles.displayVertical}>
          <Box>
            <MediaInfo article={article} />
            {isSkeleton ? (
              <Skeleton height={110} />
            ) : (
              <Typography
                variant={widerThanTablet ? 'title3' : 'title4'}
                component='h6'
                sx={{ ...styles.title, ...styles.clampedTitle }}
              >
                {article?.title}
              </Typography>
            )}
            {!widerThanTablet ? null : isSkeleton ? (
              <Skeleton height={50} />
            ) : article?.description ? (
              <Typography
                variant='title4'
                component='p'
                sx={styles.description}
              >
                {article?.description}
              </Typography>
            ) : null}
          </Box>
          <Box sx={styles.detail}>
            {Boolean(article?.date) && (
              <Typography component='p' variant='p'>
                {`${dateLabel}: ${formatFullLongDate(article.date)}`}
              </Typography>
            )}
            {showPriceLabel && Boolean(priceLabel) && (
              <>
                <div className='separator'></div>
                <Typography
                  component='p'
                  variant='p'
                  sx={article.isPremium === 0 && styles.freeLabel}
                >
                  {priceLabel}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {!Boolean(article?.image) && !isSkeleton ? null : (
          <Box sx={styles.regularImg}>
            {isSkeleton ? (
              <Skeleton
                variant='rectangular'
                height={'var(--image-size)'}
                width={'var(--image-size)'}
              />
            ) : (
              <img src={article?.image} alt='Imagen del artículo' />
            )}
          </Box>
        )}
      </Card>
    </CardActionArea>
  )
}
ArticleCardV2.propTypes = articleCardV2PropTypes
