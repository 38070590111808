import { createTheme } from '@mui/material/styles'
import { esES } from '@mui/material/locale'

import baseTheme from './base'
import * as components from './components'

// NOTE: we are compositioning the theme in two steps.
// First, we define the basic design options, such as: palette, typography, shadows...
// Then, we use these design options to compose the MUI components, such as: MuiButton...
//
// More info: https://mui.com/customization/theming/#api
const theme = createTheme(baseTheme, { components }, esES)

export default theme
