const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: {
      xs: (theme) => theme.spacing(1),
      md: (theme) => theme.spacing(1.5),
    },
    width: (theme) => `min(100%, ${theme.spacing(59.5)})`,
    height: '100%',
    mx: 'auto',
  },
  content: {
    flexGrow: 2,
  },
  title: {
    px: (theme) => theme.spacing(1),
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mx: (theme) => theme.spacing(1),
    mt: (theme) => ({
      xs: theme.spacing(1),
      lg: theme.spacing(1.5),
    }),
    mb: (theme) => ({
      xs: theme.spacing(2),
      lg: theme.spacing(3),
    }),
    '&  button': {
      width: (theme) => ({
        xs: '100%',
        md: `min(100%, ${theme.spacing(19.5)})`,
      }),
      mx: 'auto',
    },
  },
}

export default styles
