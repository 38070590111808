import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { AssociatedMediaData } from '../../propTypes'
import { MediaButton } from '..'
import styles from './associatedMedia.styles'

const associatedMediaPropTypes = {
  medias: PropTypes.arrayOf(AssociatedMediaData),
  onClickMedia: PropTypes.func,
}

const AssociatedMedia = ({ medias = [], onClickMedia }) => {
  return (
    <Box sx={styles.root} id='associated_media'>
      {medias.map((mediaData, i) => (
        <MediaButton
          key={mediaData?.id ?? i}
          onClick={onClickMedia}
          data={mediaData}
        />
      ))}
    </Box>
  )
}

AssociatedMedia.propTypes = associatedMediaPropTypes

export default AssociatedMedia
