import PropTypes from 'prop-types'
import { Box, Button, Container, Typography } from '@mui/material'

import styles from './response.styles'

const responseProptypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  ctaText: PropTypes.string,
  onClickCta: PropTypes.func,
}

const Response = ({ title, message, ctaText, onClickCta }) => {
  return (
    <Box component='section' sx={styles.root}>
      <Container>
        <Box sx={styles.slot}>
          <Box sx={styles.message}>
            <Box sx={styles.messageHeader}>
              <Typography variant='h4_clash' sx={styles.messageTitle}>
                {title}
              </Typography>
              <Typography sx={styles.messageText}>{message}</Typography>
            </Box>
            <Box sx={styles.messageWrapActions}>
              <Button color='secondary' onClick={onClickCta}>
                {ctaText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

Response.propTypes = responseProptypes

export default Response
