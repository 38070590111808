const styles = {
  dark: {
    '--WebkitTextFillColor': (theme) => theme.palette.neutral.white,
    //
    '--label--color': (theme) => theme.palette.neutral.light,
    '--label--backgroundColor': (theme) => theme.palette.neutral.darkest,
    '--label-error--color': (theme) => theme.palette.error.main,
    '--label-error--backgroundColor': (theme) => theme.palette.error.dark,
    '--label-focused--color': (theme) => theme.palette.neutral.light,
    '--label-focused--backgroundColor': (theme) => theme.palette.neutral.dark,
    '--label-focused-error--color': (theme) => theme.palette.error.main,
    '--label-focused-error--backgroundColor': (theme) =>
      theme.palette.error.dark,
    //
    '--input--color': (theme) => theme.palette.neutral.white,
    '--input--backgroundColor': (theme) => theme.palette.neutral.darkest,
    '--input__hover--color': (theme) => theme.palette.neutral.dark,
    '--input__hover--backgroundColor': (theme) => theme.palette.neutral.darkest,
    '--input-focused--backgroundColor': (theme) => theme.palette.neutral.dark,
    '--input-error--color': (theme) => theme.palette.error.main,
    '--input-error--backgroundColor': (theme) => theme.palette.error.dark,
    '--input-error__hover--borderColor': (theme) => theme.palette.error.dark,
    '--input-error__hover--backgroundColor': (theme) =>
      theme.palette.error.dark,
    '--input-error-input--color': (theme) => theme.palette.error.light,
  },
  light: {
    '--WebkitTextFillColor': (theme) => theme.palette.neutral.black,
    //
    '--label--color': (theme) => theme.palette.neutral.dark,
    '--label--backgroundColor': (theme) => theme.palette.neutral.lightest,
    '--label-error--color': (theme) => theme.palette.error.main,
    '--label-error--backgroundColor': (theme) => theme.palette.error.lightest,
    '--label-focused--color': (theme) => theme.palette.neutral.dark,
    '--label-focused--backgroundColor': (theme) => theme.palette.neutral.light,
    '--label-focused-error--color': (theme) => theme.palette.error.main,
    '--label-focused-error--backgroundColor': (theme) =>
      theme.palette.error.lightest,
    //
    '--input--color': (theme) => theme.palette.neutral.dark,
    '--input--backgroundColor': (theme) => theme.palette.neutral.lightest,
    '--input__hover--color': (theme) => theme.palette.neutral.light,
    '--input__hover--backgroundColor': (theme) =>
      theme.palette.neutral.lightest,
    '--input-focused--backgroundColor': (theme) => theme.palette.neutral.light,
    '--input-error--color': (theme) => theme.palette.error.main,
    '--input-error--backgroundColor': (theme) => theme.palette.error.lightest,
    '--input-error__hover--borderColor': (theme) =>
      theme.palette.error.lightest,
    '--input-error__hover--backgroundColor': (theme) =>
      theme.palette.error.lightest,
    '--input-error-input--color': (theme) => theme.palette.error.dark,
  },
}

export default styles
