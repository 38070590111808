import { useEffect } from 'react'
import PropTypes from 'prop-types'

import EVENTS_NAMES from '../../../../../analytics/eventsNames.json'
import { browser } from '../../../../utils'
import useEmailForm from './useEmailForm'
import View from './View'

const FORM_ID = EVENTS_NAMES.PA_FORM_SUBMIT_EMAIL

const emailStepPropTypes = {
  onContinueLogin: PropTypes.func,
  onContinueRegister: PropTypes.func,
  variant: PropTypes.object,
}

const EmailStep = ({ onSubmitEmail, variant }) => {
  const { status, submitForm } = useEmailForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  const dispatchClickEvent = (eventName) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: eventName,
        },
      }),
    )
  }

  useEffect(() => {
    if (status.result !== null) {
      onSubmitEmail({
        isNewUser: status.result.isNewUser,
        email: status.formValues.email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_PAGE_VIEW_EMAIL,
        },
      }),
    )
  }, [])

  return (
    <View
      formId={FORM_ID}
      variant={variant}
      onSubmit={handleSubmit}
      onClickGoogle={() => dispatchClickEvent(EVENTS_NAMES.PA_CLICK_GOOGLE)}
      onClickApple={() => dispatchClickEvent(EVENTS_NAMES.PA_CLICK_APPLE)}
      {...status}
      withoutSocialLogin={
        browser.isInAppBrowser() && (browser.isAndroid() || browser.isIOS())
      }
    />
  )
}

EmailStep.propTypes = emailStepPropTypes

export default EmailStep
