import { useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { openMediaLink } from '../../../utils'
import { User } from '../../../../business'
import { ArticlesList } from '../../../components'
import View from './View'

const SearchResults = ({ searchValue, onClickArticle }) => {
  const fetchArticlesBySearch = useMemo(
    () => async (pagination) =>
      await User.getArticlesBySearch(searchValue, pagination),
    [searchValue],
  )

  const articlesData = User.useArticlesList(fetchArticlesBySearch, searchValue)

  return <ArticlesList onClickArticle={onClickArticle} {...articlesData} />
}

const Search = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [searchValue, setSearchValue] = useState(state?.searchValue ?? null)

  const navigateToDiscovery = () => {
    navigate('..')
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formValues = Object.fromEntries(new FormData(ev.target))
    const searchInputValue = formValues.search

    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_SEARCH,
          text: searchInputValue,
        },
      }),
    )

    setSearchValue(searchInputValue)
  }

  const handleOpenArticle = (article) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_ARTICLE,
          interface: 'search',
          articleId: article.articleId,
          mediaId: article.mediaProviderId,
        },
      }),
    )
    openMediaLink(article.url)
  }

  return (
    <View
      initialInputValue={state?.searchValue ?? ''}
      onCancelSearch={navigateToDiscovery}
      onSubmitSearch={handleSubmit}
      renderResults={() =>
        searchValue && (
          <SearchResults
            searchValue={searchValue}
            onClickArticle={handleOpenArticle}
          />
        )
      }
    />
  )
}

export default Search
