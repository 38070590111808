import { Box, Typography } from '@mui/material'

import { useTexts } from '../../texts'
import { Icons } from '../../assets'

const styles = {
  root: {
    backgroundColor: 'neutral.lightest',
    minHeight: (theme) => theme.spacing(7.625),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 0.7,
    py: { xs: 0, md: 2.5 },
  },
}

const PayperBanner = ({ className }) => {
  const texts = useTexts()
  return (
    <Box sx={styles.root} className={className}>
      <Icons.PayperLogo
        aria-label='Payper logo'
        color='#121212'
        height={40}
        width={190.58}
        viewBox='0 0 81 17'
      />
      <Typography variant='body2'>{texts.payperSlogan()}</Typography>
    </Box>
  )
}

export default PayperBanner
