import { Box, Typography, Button } from '@mui/material'

import step0 from '../../../../../assets/images/wizard_step0.jpeg'
import step1Android from '../../../../../assets/images/wizard_step1_android.png'
import step2Android from '../../../../../assets/images/wizard_step2_android.png'
import step1IosChrome from '../../../../../assets/images/wizard_step1_ios_chrome.png'
import step1IosSafari from '../../../../../assets/images/wizard_step1_ios_safari.png'
import step2Ios from '../../../../../assets/images/wizard_step2_ios.png'

import { useTexts } from '../../../../../texts'
import { Carousel } from '../../../../components'
import styles from './addToHomeScreenWizard.styles'

export const WIZARD_VARIANTS = {
  IOS_SAFARI: 'ios_safari',
  IOS_CHROME: 'ios_chrome',
  ANDROID: 'android',
}

const IMAGES_FOR_VARIANT = {
  [WIZARD_VARIANTS.ANDROID]: {
    step1: step1Android,
    step2: step2Android,
  },
  [WIZARD_VARIANTS.IOS_SAFARI]: {
    step1: step1IosSafari,
    step2: step2Ios,
  },
  [WIZARD_VARIANTS.IOS_CHROME]: {
    step1: step1IosChrome,
    step2: step2Ios,
  },
}

const Slide = ({ content }) => (
  <Box sx={styles.slide}>
    <Typography variant='body1'>{content.title}</Typography>
    <Box>
      <img src={content.image} alt={content.imgAlt} />
    </Box>
  </Box>
)

export const View = ({ onClickClose, variant, onSlideChange }) => {
  const texts = useTexts()

  const CONTENT_BY_INDEX = {
    0: {
      title: texts.homeWizardStep0(),
      imgAlt: texts.homeWizardStep0ImgAlt(),
      image: step0,
    },
    1: {
      title:
        variant === WIZARD_VARIANTS.ANDROID
          ? texts.homeWizardStep1Android()
          : texts.homeWizardStep1Ios(),
      imgAlt: texts.homeWizardStep1ImgAlt(),
      image: IMAGES_FOR_VARIANT[variant].step1,
    },
    2: {
      title: texts.homeWizardStep2(),
      imgAlt: texts.homeWizardStep2ImgAlt(),
      image: IMAGES_FOR_VARIANT[variant].step2,
    },
  }

  return (
    <Box sx={styles.root} className='full-bleed'>
      <Box sx={styles.content}>
        <Typography component='h2' variant='h4' sx={styles.title}>
          {texts.homeWizardTitle()}
        </Typography>
        <Carousel
          slides={[0, 1, 2].map((index) => (
            <Slide content={CONTENT_BY_INDEX[index]} />
          ))}
          onSlideChange={onSlideChange}
        />
        <Button fullWidth color='secondary' onClick={onClickClose}>
          {texts.homeWizardAction()}
        </Button>
      </Box>
    </Box>
  )
}
