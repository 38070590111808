import baseTheme from '../base'

const MuiTabs = {
  defaultProps: {
    centered: true,
  },
  styleOverrides: {
    root: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTabs-flexContainer': {
        columnGap: baseTheme.spacing(0.5),
      },
    },
  },
}

export default MuiTabs
