const styles = {
  error: {
    '--text-color': (theme) => theme.palette.error.lightest,
    '--text-backgroundColor': (theme) => theme.palette.error.main,
  },
  success: {
    '--text-color': (theme) => theme.palette.neutral.white,
    '--text-backgroundColor': (theme) => theme.palette.success.main,
  },
  default: {
    '--text-color': (theme) => theme.palette.neutral.dark,
    '--text-backgroundColor': (theme) => theme.palette.neutral.light,
  },
  warning: {
    '--text-color': (theme) => theme.palette.neutral.black,
    '--text-backgroundColor': (theme) => theme.palette.primary.main,
  },
  info: {
    '--text-color': (theme) => theme.palette.info.lightest,
    '--text-backgroundColor': (theme) => theme.palette.info.main,
  },
  text: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 0.625,
    py: 0.25,
    px: 1,
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    letterSpacing: '0.1em',
    color: 'var(--text-color)',
    borderRadius: (theme) => theme.spacing(2),
    backgroundColor: 'var(--text-backgroundColor)',
  },
}

export default styles
