const styles = {
  root: {
    mt: 0,
    mb: {
      xs: 3,
      md: 4,
    },
  },
  section: {
    width: `min(100%, var(--content-L-max-width))`,
    mx: 'auto',

    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
  },
  benefitsList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  benefitsItem: {
    display: 'flex',

    '& .icon': {
      minWidth: (theme) => ({
        xs: 40,
        md: theme.spacing(3.75),
      }),
      display: 'grid',
      placeContent: { xs: 'baseline', md: 'center' },
    },
    '& .text > h5': {
      mb: 0.375,
    },
  },
  cta: {
    width: 'var(--button-max-width)',
    mx: 'auto',
  },
}

export default styles
