import { Box, Typography } from '@mui/material'

const styles = {
  title: {
    mb: { xs: 1, md: 1.25 },
    textAlign: {
      md: 'center',
    },
  },
}

const PageBlock = ({ children, title, sx = {} }) => (
  <Box sx={sx}>
    <Typography component='h2' variant='h4' sx={styles.title}>
      {title}
    </Typography>

    {children}
  </Box>
)

export default PageBlock
