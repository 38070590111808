import PropTypes from 'prop-types'
import { Avatar as MUIAvatar, Typography } from '@mui/material'

import { Icons } from '../../assets'

const styles = {
  color: 'neutral.black',
  backgroundColor: 'primary.dark',
  '& #avatar-initials': {
    fontSize: 10,
    fontWeight: 700,
  },
}

const hasNameBeenSetByPayperAdmin = (name, familyName) =>
  name === '.' && familyName === '.'

const getInitial = (str) => (str?.length > 0 ? str[0].toUpperCase() : '')

const getUserInitials = (user) =>
  getInitial(user?.name).concat(getInitial(user?.familyName))

const AvatarPropTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    image: PropTypes.string,
  }),
  size: PropTypes.number,
}

const Avatar = ({ user, size = 30 }) => (
  <MUIAvatar alt='account' sx={{ ...styles, width: size, height: size }}>
    {(!Boolean(user?.name) && !Boolean(user?.familyName)) ||
    hasNameBeenSetByPayperAdmin(user?.name, user?.familyName) ? (
      <Icons.User2 />
    ) : (
      <Typography component='span' id='avatar-initials'>
        {getUserInitials(user)}
      </Typography>
    )}
  </MUIAvatar>
)

Avatar.propTypes = AvatarPropTypes

export default Avatar
