import theme from '../../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 2,
    color: 'neutral.black',
    position: 'relative',
  },
  loader: {
    height: {
      xs: 60,
      md: 95,
    },
  },
  description: {
    ...theme.typography.body2,
    color: 'var(--text)',
    mb: {
      xs: 0.3,
      md: 0.5,
    },
  },
  mediasContainer: {
    my: {
      xs: 0,
      md: 1,
    },
  },
  mediasLogos: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    rowGap: 0,
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    p: 0,

    '& > li': {
      padding: 1,
      my: {
        xs: -0.3,
        md: 0,
      },
    },
  },
}

export default styles
