import baseTheme from '../base'

const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      marginLeft: 0,
    },
    label: {
      ...baseTheme.typography.body2,

      '& .MuiLink-root': {
        fontWeight: 600,
        color: baseTheme.palette.neutral.darkest,
      },
    },
  },
}

export default MuiFormControlLabel
