import { useLocation } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { useNavigateWithSearchParams } from '../../../utils'
import useForgottenPasswordFlow from './useForgottenPasswordFlow'
import View from './View'

const ForgottenPassword = () => {
  const { state } = useLocation()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const forgottenPassword = useForgottenPasswordFlow(state?.loginEmail)

  const redirectToLogin = () => {
    if (state?.from === 'access') {
      navigateWithSearchParams('/access', {
        state: { loginEmail: forgottenPassword?.userEmail ?? '' },
      })
    } else {
      navigateWithSearchParams('/login')
    }
  }

  const handleGoToLogin = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_CLICK_GO_TO_LOGIN,
        },
      }),
    )
    redirectToLogin()
  }

  const handleResetPasswordSuccess = () => {
    redirectToLogin()
  }

  const handleReturnToFirstStep = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_CLICK_GO_TO_FP_RESEND_CODE,
        },
      }),
    )

    forgottenPassword.handleReturnToFirstStep()
  }

  const handleCancle = () => {
    document.dispatchEvent(
      new CustomEvent('leaveForgottenPassword', {
        detail: forgottenPassword.stepsLayoutProps.stepName,
      }),
    )

    navigateWithSearchParams('/')
  }

  return (
    <View
      {...forgottenPassword}
      onCancel={handleCancle}
      onGoToLogin={handleGoToLogin}
      onContinueToSecondStep={forgottenPassword.handleContinueToSecondStep}
      onReturnToFirstStep={handleReturnToFirstStep}
      onResetPasswordSuccess={handleResetPasswordSuccess}
    />
  )
}

export default ForgottenPassword
