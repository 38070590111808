import { Box, Typography, Button } from '@mui/material'
import { useMediaQuery } from '@mui/material'

import theme from '../../../theme'
import * as PT from '../../propTypes'
import { ArticlesSkeleton, ArticleCardV2, Decorator } from '..'
import { useTexts } from '../../../texts'
import styles from './articlesList.styles'

const getIsLastElem = (arr, i) => i === arr.length - 1

const EmptyState = () => (
  <Box sx={styles.root}>
    <Box sx={styles.content}>
      <Typography sx={{ mt: 3, textAlign: 'center' }}>
        No se han encontrado resultados
      </Typography>
    </Box>
  </Box>
)

const ArticlesList = ({
  isFetching,
  articles,
  isLastPage,
  error,
  fetchNewPage,
  onClickArticle,
  dateType,
}) => {
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  const texts = useTexts()

  if (!isFetching && articles.length === 0) return <EmptyState />
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Box>
          {articles.map((article, articleIndex, arr) => (
            <Box key={article.articleId}>
              <ArticleCardV2
                article={article}
                onClick={() => onClickArticle(article)}
                dateType={dateType}
              />
              {!getIsLastElem(arr, articleIndex) && <Decorator fullWidth />}
            </Box>
          ))}
        </Box>

        {isFetching && (
          <ArticlesSkeleton
            withCategory
            withDescription={widerThanTablet ? true : false}
            orientation={PT.ARTICLE_ORIENTATION.HORIZONTAL}
            isHighlighted={widerThanTablet ? true : false}
            numberOfArticles={widerThanTablet ? 5 : 8}
          />
        )}
        {error && <Typography>Algo ha ido mal</Typography>}
      </Box>
      {!isFetching && !isLastPage && (
        <Box sx={styles.button}>
          <Button
            id='show-more'
            size='small'
            color='primary'
            onClick={fetchNewPage}
          >
            {texts.getShowMoreLabel()}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ArticlesList
