import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { Public } from '../business'
import RequireAuthenticated from './RequireAuthenticated'
import RequireNotAuthenticated from './RequireNotAuthenticated'
import { PublicLayout, MainPageLayout, SecondaryPageLayout } from './Layout'
import {
  Account,
  Access,
  BankCardEdition,
  BankCardIntroduction,
  ChromeExtensionUninstalled,
  OnboardingChromeExtension,
  Discovery,
  Error,
  ForgottenPassword,
  Home,
  OnboardingAccess,
  UpdatePassword,
  UpdatePersonalData,
  RegisterSuccess,
  Search,
  Section,
  SocialLoginCallback,
  SuccessThirdPartyIdpAccess,
  TopUpBalance,
  WidgetPurchaseWithBankCardRegistration,
  Library,
  LandingPartner,
} from './Pages'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  { path: '/dashboard/*', element: <Navigate to='/discovery' replace /> },
  {
    path: '/discovery',
    element: (
      <RequireAuthenticated>
        <Outlet />
      </RequireAuthenticated>
    ),
    children: [
      {
        index: true,
        element: (
          <MainPageLayout>
            <Discovery />
          </MainPageLayout>
        ),
      },
      {
        path: 'search',
        element: (
          <MainPageLayout withoutFooter>
            <Search />
          </MainPageLayout>
        ),
      },
      {
        path: 'section/:sectionId',
        element: (
          <SecondaryPageLayout>
            <Section />
          </SecondaryPageLayout>
        ),
      },
    ],
  },
  {
    path: '/library',
    element: (
      <RequireAuthenticated>
        <Outlet />
      </RequireAuthenticated>
    ),
    children: [
      {
        index: true,
        element: (
          <MainPageLayout>
            <Library />
          </MainPageLayout>
        ),
      },
    ],
  },
  {
    path: '/account',
    element: (
      <RequireAuthenticated>
        <Outlet />
      </RequireAuthenticated>
    ),
    children: [
      {
        index: true,
        element: (
          <MainPageLayout>
            <Account />
          </MainPageLayout>
        ),
      },
      {
        path: 'top-up-balance',
        element: (
          <SecondaryPageLayout>
            <TopUpBalance />
          </SecondaryPageLayout>
        ),
      },
      {
        path: 'bank-card-edition',
        element: (
          <SecondaryPageLayout>
            <BankCardEdition />
          </SecondaryPageLayout>
        ),
      },
      {
        path: 'bank-card-introduction',
        element: (
          <SecondaryPageLayout>
            <BankCardIntroduction />
          </SecondaryPageLayout>
        ),
      },
      {
        path: 'update-password',
        element: (
          <SecondaryPageLayout>
            <UpdatePassword />
          </SecondaryPageLayout>
        ),
      },
      {
        path: 'update-personal-data',
        element: (
          <SecondaryPageLayout>
            <UpdatePersonalData />
          </SecondaryPageLayout>
        ),
      },
    ],
  },
  {
    path: '/purchase-with-bank-card-registration',
    element: <WidgetPurchaseWithBankCardRegistration />,
    errorElement: <Error />,
  },
  {
    path: '/access',
    element: <Access />,
    errorElement: <Error />,
  },
  {
    // NOTE: for access backwards compatibility. This path could be configured inside the media's widgetConfigForAnonymousUser information as the 'redirectUri'.
    path: '/sso-login',
    element: <Navigate to={`/access${window.location.search}`} replace />,
    errorElement: <Error />,
  },
  {
    // NOTE: welcome page after registration
    path: '/register-success',
    element: (
      <RequireAuthenticated>
        <RegisterSuccess />
      </RequireAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/forgotten-password',
    element: (
      <RequireNotAuthenticated>
        <ForgottenPassword />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/social-login-callback',
    element: <SocialLoginCallback />,
    errorElement: <Error />,
  },
  {
    // NOTE: redirection from /social-login-callback
    path: '/success-3rd-party-idp-access',
    element: <SuccessThirdPartyIdpAccess />,
    errorElement: <Error />,
  },
  {
    path: '/onboarding-ce',
    element: <OnboardingChromeExtension />,
    errorElement: <Error />,
  },
  {
    path: '/onboarding-access',
    element: <OnboardingAccess />,
    errorElement: <Error />,
  },
  {
    // route with no view to be called when the chrome extension is uninstalled
    path: '/chrome-extension-uninstalled',
    element: <ChromeExtensionUninstalled />,
    errorElement: <Error />,
  },
  {
    path: '/landing',
    element: (
      <RequireNotAuthenticated>
        <PublicLayout withoutHeader>
          <LandingPartner />
        </PublicLayout>
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/',
    element: (
      <RequireNotAuthenticated>
        <PublicLayout>
          <Home />
        </PublicLayout>
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
])

const App = () => {
  document.dispatchEvent(new Event('appLoad'))

  return (
    <Public.ArticlesSectionsProvider>
      <Public.MediaProvsProvider>
        <RouterProvider router={router} />
      </Public.MediaProvsProvider>
    </Public.ArticlesSectionsProvider>
  )
}

export default App
