import { useTexts } from '../../../../texts'
import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { AssociatedMedia } from '../../../components'
import { openMediaLink } from '../../../utils'
import { ASSOCIATED_MEDIAS_DATA } from '../../../constants'
import { PageContent, PageBlock } from '../../../Layout'
import Search from './Search'
import Sections from './Sections'
import AddToHomeScreenWizard from './AddToHomeScreenWizard'
import HeroPartner from './HeroPartner'

const Discovery = () => {
  const texts = useTexts()

  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
        },
      }),
    )

    openMediaLink(mediaData.domain)
  }

  return (
    <PageContent>
      <HeroPartner />
      <Search />
      <PageBlock title={texts.getAssociatedMediaPublicTitle()}>
        <AssociatedMedia
          medias={ASSOCIATED_MEDIAS_DATA}
          onClickMedia={openMediaPage}
        />
      </PageBlock>
      <AddToHomeScreenWizard />
      <Sections />
    </PageContent>
  )
}

export default Discovery
