import { browser } from '../App/utils'

const CE_ID_STORAGE_KEY = 'payper_chrome_extension_id'
const getTestingExtensionId = () =>
  window.localStorage.getItem(CE_ID_STORAGE_KEY)

const PAYPER_CHROME_EXTENSION_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PAYPER_CHROME_EXTENSION_ID
    : getTestingExtensionId()

export const isTestingEnvWithNoChromeExtConfig = () =>
  process.env.REACT_APP_ENVIRONMENT !== 'production' &&
  !Boolean(getTestingExtensionId())

export const DOWNLOAD_CHROME_URL = 'https://www.google.com/chrome/'
export const PAYPER_CWS_URL =
  'https://chromewebstore.google.com/detail/payper/gkkpbpdpjajmhoconplkkojfkaebkokm'

const sendMessageToChromeExtension = (
  extensionId,
  requestMessage,
  onErrorResponse,
) => {
  return new Promise((resolve) => {
    try {
      const chrome = window.chrome

      if (!extensionId) {
        console.error(
          'Error sending message to Chrome Extension: the localStorage variable payper_chrome_extension_id must be defined',
        )
        resolve(onErrorResponse)
      } else if (Boolean(chrome)) {
        chrome.runtime.sendMessage(extensionId, requestMessage, (response) => {
          if (response) {
            resolve(response)
          } else {
            if (chrome.runtime.lastError) {
              console.error(
                `Error sending message to Chrome Extension for request Message ${JSON.stringify(
                  requestMessage,
                )}`,
                { error: chrome.runtime.lastError },
              )
            }
            resolve(onErrorResponse)
          }
        })
      } else {
        resolve(onErrorResponse)
      }
    } catch (error) {
      resolve(onErrorResponse)
    }
  })
}

const CE_STATUS_MESSAGE = { message: 'check_extension_status' }
const ON_CE_STATUS_MESSAGE_ERROR_RESPONSE = { isActive: false }

const checkPayperExtStatus = () =>
  sendMessageToChromeExtension(
    PAYPER_CHROME_EXTENSION_ID,
    CE_STATUS_MESSAGE,
    ON_CE_STATUS_MESSAGE_ERROR_RESPONSE,
  )

const CE_AVAILABILITY_BY_BROWSER_TYPE = {
  NOT_DESKTOP: 'not_desktop',
  DESKTOP_NOT_AVAILABLE: 'desktop_not_available',
  DESKTOP_AVAILABLE: 'desktop_available',
}

const getAvailabilityByBrowser = () => {
  if (!browser.isDesktop()) {
    return CE_AVAILABILITY_BY_BROWSER_TYPE.NOT_DESKTOP
  } else if (browser.isChrome() || browser.isEdge()) {
    return CE_AVAILABILITY_BY_BROWSER_TYPE.DESKTOP_AVAILABLE
  } else {
    return CE_AVAILABILITY_BY_BROWSER_TYPE.DESKTOP_NOT_AVAILABLE
  }
}

export const CE_AVAILABILITY = {
  ...CE_AVAILABILITY_BY_BROWSER_TYPE,
  ACTIVE: 'active',
}

const getAvailabilityType = async () => {
  let availabilityType = getAvailabilityByBrowser()

  if (availabilityType === CE_AVAILABILITY_BY_BROWSER_TYPE.DESKTOP_AVAILABLE) {
    const chromeExtensionStatus = await checkPayperExtStatus()
    if (chromeExtensionStatus.isActive) {
      availabilityType = CE_AVAILABILITY.ACTIVE
    }
  }

  return availabilityType
}

export const getAvailability = async () =>
  (await getAvailabilityType()) === CE_AVAILABILITY.ACTIVE
