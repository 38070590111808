import PropTypes from 'prop-types'
import { TextField as MuiTextField } from '@mui/material'

import { Icons } from '../../../assets'
import styles from './textField.styles'

const inputPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  Icon: PropTypes.node,
  error: PropTypes.bool,
  helperText: PropTypes.node,
}

const TextField = ({
  id,
  name,
  label,
  type,
  autoComplete,
  Icon,
  error,
  helperText,
  InputProps,
  ...rest
}) => {
  const hasIcon = InputProps && InputProps.startAdornment ? 'hasIcon' : 'noIcon'

  return (
    <MuiTextField
      id={id}
      name={name}
      label={label + `${rest.required ? ' *' : ''}`}
      autoComplete={autoComplete}
      type={type}
      InputProps={InputProps}
      error={error}
      helperText={
        error && helperText ? (
          <>
            <Icons.ExclamationTriangle />
            {helperText}
          </>
        ) : helperText ? (
          helperText
        ) : null
      }
      sx={{ ...styles[hasIcon], ...styles.root, width: 1 }}
      {...rest}
    />
  )
}

TextField.propTypes = inputPropTypes

export default TextField
