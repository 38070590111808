import EmailStep from './EmailStep'
import PasswordStep from './PasswordStep'
import UserDataStep from './UserDataStep'

// NOTE: This component is used both for Access ('/access') and OnboardingAccess ('/onboarding-access') flows

const AccessSteps = ({
  variant,
  registerEmail,
  loginEmail,
  resetAccess,
  handleSubmitEmail,
  handleFinishAuth,
  attribution,
  handleGoToForgottenPassword,
}) => (
  <>
    {Boolean(registerEmail) ? (
      <UserDataStep
        email={registerEmail}
        onChangeEmail={resetAccess}
        onContinue={(userData) =>
          handleFinishAuth({ userData, isNewUser: true })
        }
        attribution={attribution}
      />
    ) : Boolean(loginEmail) ? (
      <PasswordStep
        email={loginEmail}
        onGoToForgottenPassword={handleGoToForgottenPassword}
        onContinue={(userData) =>
          handleFinishAuth({ userData, isNewUser: false })
        }
      />
    ) : (
      <EmailStep variant={variant} onSubmitEmail={handleSubmitEmail} />
    )}
  </>
)

export default AccessSteps
