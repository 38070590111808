const styles = {
  root: {
    px: (theme) => theme.spacing(1),
    py: (theme) => theme.spacing(2),
    backgroundColor: 'neutral.light',
  },
  content: {
    width: (theme) => `min(100%, ${theme.spacing(25)})`,
    mx: 'auto',

    display: 'grid',
    gap: (theme) => theme.spacing(1.5),
    '& *': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  title: {
    textAlign: { md: 'center' },
  },
  slide: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    pb: (theme) => theme.spacing(2),

    '& > p': {
      mb: (theme) => theme.spacing(0.875),
    },
    '& > div': {
      flexGrow: 2,
      '& > img': {
        alignSelf: 'stretch',
        aspectRatio: '16 / 9',
        objectFit: 'cover',
        width: '100%',
      },
    },
  },
}

export default styles
