import React from 'react'
import { Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
// import LogoPayper from '../../LogoPayper'
import { Icons } from '../../../../assets'
import styles from './poweredByPayper.styles.js'

const PoweredByPayper = ({ mediaName }) => {
  const texts = useTexts()
  return (
    <Typography sx={styles.root}>
      {texts.getPayperMarketingLabel()}
      {Boolean(mediaName) ? `${mediaName} y ` : ''}
      <Icons.PayperLogo width={62} height={16} color='#121212' />
    </Typography>
  )
}

export default PoweredByPayper
