import { decode } from '../../../format'

const SEARCH_PARAMS = {
  ACCESS: 'access',
  STATE: 'state',
}

const NESTED_PARAMS = {
  [SEARCH_PARAMS.ACCESS]: {
    REFRESH_TOKEN: 'refresh_token',
    EMAIL: 'email',
  },
  [SEARCH_PARAMS.STATE]: {
    SUCCESS_REDIRECT_URI: 'success_redirect_uri',
    ERROR_REDIRECT_URI: 'error_redirect_uri',
    ARTICLE_PRICE: 'article_price',
    MEDIA: 'media',
    ARTICLE: 'article',
  },
}

export const STATE_PARAMS = NESTED_PARAMS[SEARCH_PARAMS.STATE]
export const ACCESS_PARAMS = NESTED_PARAMS[SEARCH_PARAMS.ACCESS]

const validateHasStateData = (state) => {
  return (
    Boolean(state) &&
    Boolean(state?.[STATE_PARAMS.ARTICLE_PRICE]) &&
    Boolean(state?.[STATE_PARAMS.SUCCESS_REDIRECT_URI]) &&
    Boolean(state?.[STATE_PARAMS.ERROR_REDIRECT_URI])
  )
}

const validateHasAccessData = (access) => {
  return (
    Boolean(access) &&
    Boolean(access?.[ACCESS_PARAMS.REFRESH_TOKEN]) &&
    Boolean(access?.[ACCESS_PARAMS.EMAIL])
  )
}

const formatMediaData = (state) => {
  const media = state?.[STATE_PARAMS.MEDIA]
    ? decode(state[STATE_PARAMS.MEDIA])
    : {}
  const article = state?.[STATE_PARAMS.ARTICLE]
    ? decode(state[STATE_PARAMS.ARTICLE])
    : {}

  return {
    mediaProviderId: media?.id,
    mediaProviderName: media?.name,
    articleId: article?.id,
    articleUrl: article?.url,
    articleHeadline: article?.headline,
  }
}

const useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const state = decode(searchParams.get(SEARCH_PARAMS.STATE))
  const hasStateData = validateHasStateData(state)

  const accessData = decode(searchParams.get(SEARCH_PARAMS.ACCESS))
  const hasAccessData = validateHasAccessData(accessData)

  return {
    state,
    hasStateData,
    mediaData: formatMediaData(state),
    accessData,
    hasAccessData,
  }
}

export default useSearchParams
